import { simplify } from "../../simplify"
import { SHOULD_SHOW_STATS, addBorderToCanvas, log2canvas, getTileRange, parseColor, log } from "../common/utils"
import { densityFilter } from "../density/densityFilter"
import { labelFilter } from "../label/labelFilter"
import { ContinueFunction, LookupTable, PolygonsData, ColorOptions, Label } from "../types"
import { PolygonContainer } from "./PolygonContainer"

/**
 * Base OpenSeaDragon filter for polygons
 * Draws either a summary view, encompassed in the "lookupTables", or the raw polygons data to a JS canvas.
 * Also draws big polygons if they are available in the summary view.
 * 
 * @param ctx OpenSeaDragon Tile canvas context
 * @param next Function to be called when done with processing to trigger the next filter
 * @param tile The OpenSeadragon.Tile that is being processed by this respective pass
 */
export function polygonFilter(
    ctx: CanvasRenderingContext2D,
    next: ContinueFunction,
    tile: OpenSeadragon.Tile,
    lookupTables: LookupTable[],
    polygonData: PolygonsData,
    colorOptions: ColorOptions,
    labels: Label[]
) {
    // The next() function must _always_ be called when done
    const t0 = performance.now()
    ctx.save()

    const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
    const viewport = viewer.viewport
    const tileZoom = tile.level

    const fillColor = colorOptions.fillColor
    // Set the base color, the opacity is modified in the drawing functions
    ctx.fillStyle = `rgb(${fillColor.r}, ${fillColor.g}, ${fillColor.b})`
    ctx.globalAlpha = fillColor.a

    // Get base tiles inside this tile
    const vc2ic = viewport.viewportToImageCoordinates.bind(viewport) // function to convert viewport to universal image coordinates
    const tileTopLeft: OpenSeadragon.Point = vc2ic(tile.bounds.getTopLeft())
    const tileBottomRight: OpenSeadragon.Point = vc2ic(tile.bounds.getBottomRight())
    const tileSize: OpenSeadragon.Point = vc2ic(tile.bounds.getSize())


    // Show summary/density view when there is no polygon data or zoomed in enough
    // Given that drawing raw polygons is way more expensive than drawing the density view, this should actually depend on
    // a performance metric, instead of being hard coded. e.g. IE11 on a pentium should only draw polygons on the lowest level
    // and a M2 Pro Mac on Safari should show it on many levels
    const isIE11 = typeof window['CollectGarbage'] == 'function'
    const zoomLevel = isIE11 ? 1 : 8
    const showDensity = !polygonData || tileSize.x > 256 * zoomLevel + 1 // Always add one for floating diff
    
    // Draw density lookup tables if user is zoomed out
    if (showDensity) densityFilter(ctx, tileTopLeft, tileBottomRight, tileSize, lookupTables, colorOptions)
    
    // Draw the big polygons if the user is zoomed out, and the data is loaded and present
    if (showDensity && polygonData) polygonFilterRaw(ctx, tileTopLeft, tileBottomRight, tileSize, polygonData, colorOptions.fixedLineWidth, true)
    
    // If user is zoomed in and polygon data is loaded, try to show it
    if (!showDensity && polygonData) polygonFilterRaw(ctx, tileTopLeft, tileBottomRight, tileSize, polygonData, colorOptions.fixedLineWidth, false)

    // Show any labels we might have
    ctx.restore()
    ctx.save()
    if (labels.length > 0) labelFilter(ctx, () => {}, tile, labels)

    const timeTotal = performance.now() - t0
    // Log some stuff to the canvas
    if (SHOULD_SHOW_STATS) {
        addBorderToCanvas(ctx, 3)
        log2canvas(ctx, `Z ${tileZoom} ${timeTotal.toFixed(1)} ms polys`, 1)
    }
    // continue to the next filter
    ctx.restore()
    next()
}

/**
 * Function to draw the relevant polygons on top of an OpenSeaDragon tile
 * 
 * @param ctx The OpenSeaDragon Tile canvas context
 * @param tileTopLeft The point of the most top left coordinate of the OSD tile
 * @param tileBottomRight The point of the most bottom right coordinate of the OSD tile
 * @param openSeaDragonTileSize The size in image x and y of the OSD tile
 * @param fixedLineWidth The fixed width of a polygon line, so this actually scales when zooming in/out
 * @param polygonContainer Container of all polygons, that is queried for the polygons candidates
 */
function polygonFilterRaw(
    ctx: CanvasRenderingContext2D,
    tileTopLeft: OpenSeadragon.Point,
    tileBottomRight: OpenSeadragon.Point,
    openSeaDragonTileSize: OpenSeadragon.Point,
    polygonContainer: PolygonContainer,
    fixedLineWidth?: number,
    useBigPolygons = false
) {
    const polyRange = getTileRange(tileTopLeft, tileBottomRight, polygonContainer.tileSize, polygonContainer.tileSize)
    const tileFactor = Math.sqrt(openSeaDragonTileSize.x / 256) // 1 for highest level, 2 for one above, 4 for above that etc.

    const defaultLineWidth = useBigPolygons ? 5 : 1.5
    const lineWidth = fixedLineWidth ? fixedLineWidth / tileFactor : defaultLineWidth // Fixed lineWidth is a width that scale with the zoom!
    const tileWidth = tileBottomRight.x - tileTopLeft.x
    // These polygons bounding box overlap with the current tile
    const polygonCandidates = polygonContainer.getTileRange(polyRange, useBigPolygons)
    
    // Draw every polygon candidate
    for (const polygon of polygonCandidates) {
        // We need to convert the raw image point coordinates to the coordinates on this canvas
        const lastI = polygon.vertices.length - 1
        const finalLine = new Uint32Array([
            polygon.vertices[lastI - 1], polygon.vertices[lastI], // xy last point
            polygon.vertices[0], polygon.vertices[1] // xy first point
        ])
        
        const tolerance = tileWidth > 256 ? Math.round(tileWidth / 256) * 2 : 1
        const relevantPolylines = simplifyPolygon(polygon.vertices, tileTopLeft, openSeaDragonTileSize, tolerance)

        const canvasVertices = convertImageVertices2canvas(ctx, relevantPolylines, tileTopLeft, openSeaDragonTileSize)

        // Then draw them
        drawPolygon(ctx, canvasVertices, [], lineWidth)
    }
}


const simplifiedPolygonCache: Map<string, Uint32Array> = new Map()
window["simplifiedPolygonCache"] = simplifiedPolygonCache
function simplifyPolygon(
    imageVertices: Uint32Array,
    tileTopLeft: OpenSeadragon.Point,
    tileSize: OpenSeadragon.Point,
    tolerance = 5
) {
    enum Direction {
        Above,
        Below,
        Left,
        Right,
        Inside
    }

    function getPointDir(xImg: number, yImg: number) {
        if (xImg > tileTopLeft.x + tileSize.x) return Direction.Right
        else if (xImg < tileTopLeft.x) return Direction.Left
        else if (yImg > tileTopLeft.y + tileSize.y) return Direction.Below 
        else if (yImg < tileTopLeft.y) return Direction.Above
        else return Direction.Inside
    }

    const t0 = performance.now()
    const cacheKey = `${imageVertices.byteOffset}-${imageVertices.byteLength}-${tolerance.toFixed(1)}`
    if (!simplifiedPolygonCache.has(cacheKey) && tolerance > 0) {
        simplifiedPolygonCache.set(cacheKey, simplify(imageVertices, tolerance, false))
    }

    const polygon = tolerance > 0 ? simplifiedPolygonCache.get(cacheKey) : imageVertices

    const t1 = performance.now()
    const relevantLines: Uint32Array[] = []
    let curLine: number[] = []

    let lastDirection = getPointDir(polygon[0], polygon[1]) // Always include the first point
    // Determine which lines are relevant the current tile

    for (let i = 2; i < polygon.length + 2; i += 2) {
        // make _i_ loop to the first 2 points, since there is an implicit line between the last point
        // and the first
        const xImg = polygon[i % polygon.length]
        const yImg = polygon[(i + 1) % polygon.length]

        // Ignore points that are out of the tile.
        const pointDir = getPointDir(xImg, yImg)

        const lineCanCrossTile = lastDirection === Direction.Inside 
            || pointDir === Direction.Inside
            || lastDirection !== pointDir

        lastDirection = pointDir

        if (!lineCanCrossTile) {
            // Add the current line if needed
            if (curLine.length > 0) relevantLines.push(new Uint32Array(curLine))
            curLine = []
            continue
        }
        if (curLine.length === 0) curLine.push(polygon[i - 2], polygon[i - 1])

        curLine.push(xImg, yImg)
    }
    if (curLine.length > 0) relevantLines.push(new Uint32Array(curLine))

    if (imageVertices.length > 10 * 1000) log(`Calculating simplifiedPolygon took ${(t1 - t0).toFixed(1)} ms took, polyLines ${(performance.now() - t1).toFixed(1)} ms `, 
        imageVertices.length, polygon.length, relevantLines.reduce((acc, cur) => acc += cur.length, 0)
    )
    
    return relevantLines
}

/**
 * Function to draw a polygon, with a potential list of negative polygons inside of it, on top of a canvas.
 * The positive parts of the polygon are filled-in.
 * 
 * @param ctx The OpenSeaDragon Tile canvas context
 * @param positiveVertices The vortices of the polygon that needs to be drawn
 * @param negativeVerticesArr An array of negative polygons that intersect with the positive polygon
 * @param lineWidth The width used for the polyline
 */
function drawPolygon(
    ctx: CanvasRenderingContext2D,
    positiveVertices: Float32Array[],
    negativeVerticesArr: Float32Array[][],
    lineWidth = 2
) {
    const positiveAlpha = 0
    const coloring = parseColor(ctx.fillStyle as string)
    const outlineAlpha = ctx.globalAlpha
    ctx.strokeStyle = `rgb(${coloring.r}, ${coloring.g}, ${coloring.b})`

    // If there are no negative polygons, we simply just draw the polygon directly on the canvas
    if (negativeVerticesArr.length === 0) return drawPolygonRaw(ctx, positiveVertices, positiveAlpha, outlineAlpha, lineWidth)
    // Draw a polygon with a negative inside it
    let canvasTemp = document.createElement("canvas")
    let ctxTemp = canvasTemp.getContext("2d");
    ctxTemp.canvas.width = ctx.canvas.width
    ctxTemp.canvas.height = ctx.canvas.height

    ctxTemp.strokeStyle = ctx.fillStyle
    ctxTemp.fillStyle = ctx.fillStyle
    // Draw the positive polygon
    drawPolygonRaw(ctxTemp, positiveVertices, positiveAlpha, outlineAlpha, lineWidth)

    // Remove the negative polygon
    for (const negativeVertices of negativeVerticesArr) drawPolygonRaw(ctxTemp, negativeVertices, null, outlineAlpha, lineWidth * 2)
    ctxTemp.globalCompositeOperation = "destination-out";
    for (const negativeVertices of negativeVerticesArr) drawPolygonRaw(ctxTemp, negativeVertices, 1, null, null)
    ctxTemp.fill()

    // Copy over the result to the original canvas
    ctx.drawImage(canvasTemp, 0, 0)
    // Delete the temporary canvas
    ctxTemp = null
    canvasTemp = null
}

/**
 * Draws a polygon on top of a canvas
 * 
 * @param ctx The canvas on which the vertices are drawn
 * @param allPoints Vertices the polygon consists off: [x1, y1, x2, y2, ...]
 * @param fillAlpha Opacity the polygon should filled in at (0 to 1)
 * @param strokeAlpha Opacity the polygon border should (0 to 1)
 * @param lineWidth Width of the border
 */
function drawPolygonRaw(
    ctx: CanvasRenderingContext2D,
    allLines: Float32Array[], 
    fillAlpha: number,
    strokeAlpha: number,
    lineWidth: number
) {
    if (lineWidth) ctx.lineWidth = lineWidth;
    const origGlobalAlpha = ctx.globalAlpha
    if (fillAlpha) ctx.globalAlpha = fillAlpha
    if (strokeAlpha) ctx.globalAlpha = strokeAlpha

    // allLines = [[x1, y1, x2, y2], [x32, y32, x33, y33]]

    for (const line of allLines) {
        ctx.beginPath()
        ctx.moveTo(line[0], line[1])
        for (let i = 2; i < line.length; i += 2) {
            ctx.lineTo(line[i], line[i + 1])
        }

        if (fillAlpha) ctx.fill()
        if (strokeAlpha) ctx.stroke()
    }
    ctx.globalAlpha = origGlobalAlpha
}

type Point = number[] // x, y

/**
 * Utility to convert image coordinates from polygons to canvas coordinates.
 * 
 * @param ctx The OpenSeaDragon Tile canvas context
 * @param imageVertices An array of image x and y pairs.
 * @param tileTopLeft The point of the most top left coordinate of the OSD tile
 * @param tileSize Size of the OpenSeaDragon tile
 * @returns 
 */
function convertImageVertices2canvas(
    ctx: CanvasRenderingContext2D,
    polyLines: Uint32Array[],
    tileTopLeft: OpenSeadragon.Point,
    tileSize: OpenSeadragon.Point
) {
    /** Convert 2 canvas coordinates */
    function c2c(xImg: number, yImg: number): Point {
        // Convert to coordinates on the canvas
        const xInTile = xImg - tileTopLeft.x
        const yInTile = yImg - tileTopLeft.y

        const xRatioInTile = xInTile / tileSize.x 
        const yRatioInTile = yInTile / tileSize.y
        const xInCanvas = xRatioInTile * ctx.canvas.width
        const yInCanvas = yRatioInTile * ctx.canvas.height
        return [xInCanvas, yInCanvas]
    }

    const canvasLines = polyLines.map(line => {
        const canvasLine = new Float32Array(line.length)

        for (let i = 0; i < line.length; i += 2) {
            const x = line[i]
            const y = line[i + 1]
            const newXY = c2c(x ,y)
            canvasLine[i] = newXY[0]
            canvasLine[i + 1] = newXY[1]
        }
        return canvasLine
    })
    return canvasLines
}
