import { EfficientArray } from "../../common/EfficientArray";
import { log, log2screen } from "../../common/utils";
import { omegaDecode, OmegaEncodingType } from "./eliasOmegaDecoder";

/**
 * Small class that wraps a byte buf and keeps an internal pointer that moves forward
 * after a read call
 */
class BufReader {
    buf: Uint8Array | null = null
    pointer = 0

    constructor(buf: Uint8Array) {
        this.buf = buf
    }

    /**
     * Reads a number of bytes from the buffer
     * 
     * @param numBytes Number of bytes to read
     * @returns requested bytebuf
     */
    read(numBytes: number) {
        if (!this.buf) throw new Error('no buf')
        const slice = this.buf.slice(this.pointer, this.pointer + numBytes)
        this.pointer += numBytes
        return slice
    }
    
}

/**
 * Reads the next 4 bytes of a buffer as a 4-byte unsigned integer
 * @param reader Instance of BufReader 
 * @returns the number
 */
function readInt(reader: BufReader) {
    const slice = reader.read(4)
    const typedViewer = new Uint32Array(slice.buffer)
    return typedViewer[0]
}

/**
 * Reads an Elias Omega Encoded array at the current BufReader offset
 * @param reader BufReader instance
 * @param type The type used to during Omega Encoding
 * @returns The array of numbers that was encoded
 */
function readEncArr(reader: BufReader, type: OmegaEncodingType) {
    const arrByteLen = readInt(reader)
    const arrByteSlice = reader.read(arrByteLen)
    const nums = omegaDecode(arrByteSlice, type)
    return nums
}

/**
 * Reads and parses the raw data encoded in a byte buffer.  
 * Does not actually reconstruct the polygons.
 * 
 * @param bytes Raw Uint8Array Buffer containing the encoded polygons
 * @returns The needed data to reconstruct the polygons
 */
export function readFromBytes(bytes: Uint8Array) {
    const reader = new BufReader(bytes)
    const tileSize = readInt(reader)
    const numRows = readInt(reader)
    const numCols = readInt(reader)

    // Read the length of the encoded polygons
    const polygon_lengths_byte_len = readInt(reader)
    const polygon_lengths_bytes = reader.read(polygon_lengths_byte_len)
    const polygonLengths = new Uint32Array(polygon_lengths_bytes.buffer)

    const xJumps = readEncArr(reader, 'integers')
    const yJumps = readEncArr(reader, 'integers') 
    const num_points_in_tile = readEncArr(reader, 'naturalOnly')

    const remaindersLen = readInt(reader)
    const remainders = reader.read(remaindersLen)
    return { tileSize, numRows, numCols, polygonLengths, xJumps, yJumps, num_points_in_tile, remainders }
}

/**
 * Decodes the polygons using a tile based encoding format
 * 
 * @param xJumps The jumps that need to be made to go to new tile, in the x direction
 * @param yJumps The jumps that need to be made to go to new tile, in the y direction
 * @param num_points_in_tile Number of points that need to be read from the current tile
 * @param tileSize The size of each tile in pixels (i.e. 256)
 * @param remainders The actual point value buffer [image_x_1 % 256, image_y_1 % 256, ...]
 * @param polygonLengths The number of points in each of the polygons
 * 
 * @returns An EfficientArray that contains all polygons in a decoded uint_32t format
 */
function decodePolygons(
    xJumps: number[], 
    yJumps: number[], 
    num_points_in_tile: number[], 
    tileSize: number, 
    remainders: Uint8Array, 
    polygonLengths: Uint32Array
) {
    let tile_x = 0
    let tile_y = 0

    const num_points = Math.floor(remainders.length / 2)
    
    polygonLengths = polygonLengths.slice()
    const polygons = new EfficientArray()
    let curPolygon: number[] = []

    let remainder_i = 0
    for (let i = 0; i < xJumps.length; i++) {
        const xJump = xJumps[i]
        const yJump = yJumps[i]
        
        tile_x += xJump
        tile_y += yJump

        const num_points_in_this_tile = num_points_in_tile[i]
        for (let j = 0; j < num_points_in_this_tile; j++) {
            if (remainder_i / 2 >= num_points) {
                // Because the jump tables are stored as a bitarry with suffixing zero's, these are mistaken for encoded 1's
                // so we calc the real number of points and see if we need to stop
                break
            }
            const x_in_tile = remainders[remainder_i]
            const y_in_tile = remainders[remainder_i + 1]

            const img_x = tile_x * tileSize + x_in_tile
            const img_y = tile_y * tileSize + y_in_tile

            curPolygon.push(img_x, img_y)
            if (curPolygon.length == polygonLengths[0]) {
                // We are done with this polygon, add it to the effecient array
                // and shift the lengths array
                polygons.addValues(curPolygon)
                polygonLengths = polygonLengths.subarray(1)
                curPolygon = []
            }

            remainder_i += 2
        }
    }

    return polygons
}

/**
 * Decodes a list of polygons from a byte buffer
 * 
 * @param bytes Raw buffer containing the encoded polygons
 * @returns An EfficientArray that contains all polygons in a decoded image x and y uint_32t format
 */
export function decodePolygonsFromBytes(bytes: Uint8Array) {
    log2screen(`Start decoding polygons from ${bytes.length} bytes`)
    const encodedPolygon = readFromBytes(bytes)
    const { tileSize, numRows, numCols, polygonLengths, xJumps, yJumps, num_points_in_tile, remainders } = encodedPolygon
    const polygons = decodePolygons(xJumps, yJumps, num_points_in_tile, tileSize, remainders, polygonLengths)
    log2screen(`Done decoding ${polygons.length} polyons`)
    log("decoded", polygons, "polygons")
    return polygons
}