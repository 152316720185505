/*
 (c) 2017, Vladimir Agafonkin
 Simplify.js, a high-performance JS polyline simplification library
 mourner.github.io/simplify-js
*/
/*
Edited by Bart Grosman to use TypedArrays, which is faster
than the native Array implementation
*/

class PushAbleUint32Array {
    valuesArray: Uint32Array = new Uint32Array()
    length = 0
    constructor (numMaxEntries: number) {
        this.valuesArray = new Uint32Array(numMaxEntries)
    }

    push(entry: number) {
        this.valuesArray[this.length] = entry
        this.length++
    }

    squish() {
        return this.valuesArray.slice(0, this.length)
    }
}

// to suit your point format, run search/replace for '.x' and '.y';
// for 3D version, see 3d branch (configurability would draw significant performance overhead)

// square distance between 2 points
function getSqDist(points: Uint32Array, p1Index: number, p2Index: number) {
    const dx = points[p1Index] - points[p2Index]
    const dy = points[p1Index + 1] - points[p2Index + 1]

    return dx * dx + dy * dy;
}

// square distance from a point to a segment
function getSqSegDist(pointI: number, p1i: number, p2i: number, points: Uint32Array) {

    const px = points[pointI]
    const py = points[pointI + 1]
    const p1x = points[p1i]
    const p1y = points[p1i + 1]
    const p2x = points[p2i]
    const p2y = points[p2i + 1]

    let x = p1x
    let y = p1y
    let dx = p2x - x
    let dy = p2y - y

    if (dx !== 0 || dy !== 0) {
        var t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);

        if (t > 1) {
            x = p2x;
            y = p2y;
        } else if (t > 0) {
            x += dx * t;
            y += dy * t;
        }
    }

    dx = px - x;
    dy = py - y;

    return dx * dx + dy * dy;
}
// rest of the code doesn't care about point format

// basic distance-based simplification
function simplifyRadialDist(points: Uint32Array, sqTolerance: number) {
    let prevPointI = 0
    // Allocate a new buffer that could hold all the points, and add the first point
    let newPoints = new Uint32Array(points.length)
    newPoints[0] = points[0]
    newPoints[1] = points[1]

    let numNewPoints = 1;
    for (let i = 2; i < points.length; i += 2) {
        const isFarPoint = getSqDist(points, prevPointI, i) > sqTolerance
        const isLastPoint = i == points.length - 2

        // console.log(i, prevPointI, getSqDist(points, prevPointI, i), isLastPoint, points.length)

        if (isFarPoint || isLastPoint) {
            newPoints[numNewPoints * 2] = points[i]
            newPoints[(numNewPoints * 2) + 1] = points[i + 1]
            prevPointI = i
            numNewPoints++
        }
    }

    // Trim newPoints to it's real length
    newPoints = newPoints.slice(0, numNewPoints * 2)
    return newPoints;
}

function simplifyDPStep(points: Uint32Array, firstI: number, lastI: number, sqTolerance: number, simplified: PushAbleUint32Array) {
    let maxSqDist = sqTolerance
    let farPointIndex: number;

    for (let i = firstI + 2; i < lastI; i += 2) {
        const sqDist = getSqSegDist(i, firstI, lastI, points);

        if (sqDist > maxSqDist) {
            farPointIndex = i;
            maxSqDist = sqDist;
        }
    }

    if (maxSqDist > sqTolerance) {
        // Break the line up into 2 parts, the line toward the "outlier" point from the start, and from
        // the outlier point to the end
        if (farPointIndex - firstI > 1) simplifyDPStep(points, firstI, farPointIndex, sqTolerance, simplified);
        
        simplified.push(points[farPointIndex])
        simplified.push(points[farPointIndex + 1])

        if (lastI - farPointIndex > 1) simplifyDPStep(points, farPointIndex, lastI, sqTolerance, simplified);
    }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points: Uint32Array, sqTolerance: number) {
    const lastI = points.length - 2;

    const simplified = new PushAbleUint32Array(points.length)
    simplified.push(points[0])
    simplified.push(points[1])

    simplifyDPStep(points, 0, lastI, sqTolerance, simplified)
    
    simplified.push(points[lastI])
    simplified.push(points[lastI + 1])

    const simplifiedArr = simplified.squish()
    return simplifiedArr
}

// both algorithms combined for awesome performance
export function simplify(points: Uint32Array, tolerance: number, highestQuality: boolean) {
    if (points.length <= 4) return points

    const sqTolerance = tolerance * tolerance
    let newPoints = highestQuality ? points : simplifyRadialDist(points, sqTolerance)
    newPoints = simplifyDouglasPeucker(newPoints, sqTolerance)

    return newPoints
}
