// Fetching utils
import { decode } from "@msgpack/msgpack";
import { log, log2screen } from "./utils";
import { reviveMaskFromTar, polygonReviver } from "../polygon/reviveUtils";
import { HeatmapData, Label, MaskData, PolygonsData } from '../types'
import { PolygonContainer } from "../polygon/PolygonContainer";
import { decodePNG } from "../mask/pngDecoder/decodePNG";

/**
 * Fetches and msgpack unpacks a data stream
 * 
 * @param url URL of the mspack data
 * @returns A decoded MSGpack object, still needs to be revived into their respective classes
 */
async function fetchAndUnpack(url: string) {
    const rawResp = await fetch(url)
    if (rawResp.headers.get("Content-Type") !== 'application/msgpack') {
        throw new Error('Incorrect content type returned')
    }
    let reponseBlob = await rawResp.arrayBuffer()
    const result = decode(reponseBlob)

    // Do a manual GC call for IE11
    if (typeof window['CollectGarbage'] == 'function') {
        (window as any).CollectGarbage()
        log('IE11: Cleared memory')
    }
    return result
}

/**
 * Fetches and parses all the available density maps. Progressively, so the coarsest loads fastest.
 * 
 * @param uuid Annotation code used in fetch
 
 * @returns An array of density PNG promises
 */
function fetchLookupTablesProgressively(uuid: string) {
    // Progressively download higher resolution density lookup tables
    log2screen('Start lookup fetches')
    const coarse = fetchDensityPNG(uuid, 256)
    coarse.then(() => log2screen('Done with course'))
   
    const fine = fetchDensityPNG(uuid, 32)
    fine.then(() => log2screen('Done with fine fetchLookupPNG'))

    const densityTables = [coarse, fine]

    return densityTables
}

/**
 * Fetches a density PNG from the server, parses it, and returns the PNG canvas
 * 
 * @param code Annotation code used in fetch
 * @param size Number of original pixels corresponding to 1 density map pixel
 * @returns 
 */
async function fetchDensityPNG(uuid: string, size: number) {
    const annoUrlPrefix = `/Image/HighPerformanceDataFile?uuid=${uuid}`
    const url = annoUrlPrefix + `&fileName=lookup-tables/density_${size}px.png`

    const respBody = await fetch(url).then(r => r.arrayBuffer())
    const pngCanvas = decodePNG(new Uint8Array(respBody), 1)
    if (isCanvasBroken(pngCanvas)) throw new Error('Broken canvas, unable to set imgData')
    return {
        canvas: pngCanvas,
        tile_size: size
    }
}

/**
 * iOS webkit does not support canvases with more than 16.777.216 pixels. (4096x4096)  
 * Instead of throwing an error, it will simply be an immutable white canvas. This function
 * checks if this is the case, so we can throw an error ourselves.
 * 
 * https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit/
 * 
 * @param canvas HTML canvas that is potentially non-mutable 
 * @returns Boolean whether the canvas is immutable
 */
function isCanvasBroken(canvas: HTMLCanvasElement) {
    // Get the pixel data for the most top left pixel and try to mutate it.
    const ctx = canvas.getContext('2d')
    const imgData = ctx.getImageData(0, 0, 1, 1)
    const before = imgData.data[0] !== 255 ? imgData.data[0] : 253
    imgData.data[0] = before + 1
    ctx.putImageData(imgData, 0, 0)
    const newImgData = ctx.getImageData(0, 0, 1, 1)

    if (newImgData.data[0] === before && before === 0) return true
    if (newImgData.data[0] === before) {
        console.warn('Weird, unable to change pixel but not 0')
        return true
    } else return false
}

/**
 * Fetches and revives mask (sometimes called points) data from the server.
 * The TAR file contains images for each 256x256px tile that has any points.
 * 
 * @param uuid Annotation code used in fetch
 * @returns MaskData
 */
async function fetchMaskItemData(uuid: string) {
    log2screen('Start with mask itemData')
    // Fetch and revive the masksArray data
    const resp = await fetch(`/Image/HighPerformanceDataFile?uuid=${uuid}&fileName=masks.tar.gz`)
    if (resp.headers.get("Content-Type") !== 'application/x-tar') {
        throw new Error('Incorrect content type returned')
    }

    const tarBuf = await resp.arrayBuffer()
    log2screen('Done with mask tar itemData fetch: ' + tarBuf.byteLength)
    const maskArr = reviveMaskFromTar(new Uint8Array(tarBuf))
    log2screen(`Done with mask revive`)

    return {
        masks: maskArr,
        tileWidth: 256,
        tileHeight: 256
    }
}

/**
 * Fetches and revives a polygonContainer from the server 
 * 
 * @param uuid Annotation code used in fetch
 * @param folder Which folder in the annotation ZIP to retrieve the polygons information from
 * @returns PolygonContainer
 */
async function fetchPolygonsAndRevive(uuid: string, folder: string) {
    const prefix = `/Image/HighPerformanceDataFile?uuid=${uuid}&fileName=${folder}/`
    try {
        // Fetch all the information needed to revive a polygonContainer from the server
        const promises = [
            fetch(prefix + 'negative_polygons.json').then(r => r.json()),
            fetchAndUnpack(prefix + 'tile_polygons_i.msgpack.br'),
            fetchAndUnpack(prefix + 'big_tile_polygons_i.msgpack.br'),
            fetch(prefix + 'encoded_polygons.bin.br').then(r => r.arrayBuffer())
        ]
        const results = await Promise.all(promises)
        const negativePolygons = results[0] as any
        const tilePolygonIndices = results[1] as any
        const bigPolygonIndices = results[2] as any

        const encodedPolygonsBuf = results[3] as ArrayBuffer
        const encodedPolygons = new Uint8Array(encodedPolygonsBuf)
        
        const polygonContainer = polygonReviver(negativePolygons, tilePolygonIndices, bigPolygonIndices, encodedPolygons)
        return polygonContainer
    } catch(err) {
        console.error("Failed to revive polygons: ", err)
        return new PolygonContainer()
    }
}

async function fetchHeatmapData(uuid: string) {
    const prefix = `/Image/HighPerformanceDataFile?uuid=${uuid}`
    const metadata = await fetch(prefix + '&fileName=heatmap_metadata.json').then(r => r.json())
    const {x, y, sizePerPixel} = metadata
    const pngBytes = await fetch(prefix + '&fileName=heatmap.png').then(r => r.arrayBuffer())
    return  {
        img: {
            pngBytes
        },
        sizePerPixel,
        x,
        y
    }
}

async function fetchLabels(uuid: string) {
    const prefix = `/Image/HighPerformanceDataFile?uuid=${uuid}`
    const labelsFetch = await fetch(prefix + '&fileName=labels.json')
    if (labelsFetch.status !== 200) return []
    const labels: Label[] = await labelsFetch.json()
    if (!Array.isArray(labels)) return []
    return labels
}

/**
 * Fetches, unpacks and revives the anno2 data for a uuid
 * 
 * @param uuid The uuid for the anno2 data, is send to the server to retrieve their files
 * @returns A promise resulting in lookuptables and a revived item data promise
 */
export async function getData(uuid: string) {
    log2screen(`Starting fetch for uuid: ${uuid}`)
    const annoUrlPrefix = `/Image/HighPerformanceDataFile?uuid=${uuid}`
    const systemMetadata = await fetch(annoUrlPrefix + '&fileName=system_metadata.json').then(r => r.json())
    const type: 'points' | 'polygons' | 'heatmap' | 'circles' = systemMetadata.type
    const metadata = await fetch(annoUrlPrefix + '&fileName=user_metadata.json').then(r => r.json())
    const labels: Label[] = type === 'polygons' ? await fetchLabels(uuid) : []

    // get the density lookup tables, course to fine
    const densityTablesProms = type !== 'heatmap' ? fetchLookupTablesProgressively(uuid) : []
    
    // Fetch the itemData after all the density tables are finished
    const itemDataProm: Promise<MaskData | PolygonsData | HeatmapData> = Promise.allSettled(densityTablesProms).then(async () => {
        if (type === 'points') return fetchMaskItemData(uuid)
        else if (type === 'circles') return fetchMaskItemData(uuid)
        else if (type === 'polygons') return fetchPolygonsAndRevive(uuid, 'polygon_container')
        else if (type === 'heatmap') return fetchHeatmapData(uuid)
        else throw new Error('Unknown type')
    })


    const data = {
        metadata,
        lookupTableProms: densityTablesProms,
        itemDataProm,
        labels,
        type,
        numItems: systemMetadata.numItems as number | null
    }
    log2screen(`Done fetching: ${uuid}: ${systemMetadata.version} ${systemMetadata.type}`)

    return data
}
