import { turboColorMap } from "../common/turboColorMap"
import { copyCtx, parseColor } from "../common/utils"
import { LookupTable, ColorOptions } from "../types"

/**
 * Function to draw tile-based density information atop of an OpenSeaDragon tile.
 * The density information can be, and is most of the time, of a higher resolution than the tile.
 * 
 * @param ctx The OpenSeaDragon Tile canvas context
 * @param tileTopLeft The point of the most top left coordinate of the OSD tile
 * @param tileBottomRight The point of the most bottom right coordinate of the OSD tile
 * @param openSeaDragonTileSize The size in image x and y of the OSD tile
 * @param lookupTables The object from which the density information is extracted
 */
export function densityFilter(
    ctx: CanvasRenderingContext2D,
    tileTopLeft: OpenSeadragon.Point,
    tileBottomRight: OpenSeadragon.Point,
    openSeaDragonTileSize: OpenSeadragon.Point,
    lookupTables: LookupTable[],
    colorOptions: ColorOptions
) {
    const desiredDensity = (tileBottomRight.x - tileTopLeft.x) / 64
    const bestDensityOption = lookupTables.reduce(function(curBest, curr) {
        const curSize = curr.tile_size
        const curDiff = Math.abs(curSize - desiredDensity)
        const bestSize = curBest.tile_size
        const bestDif = Math.abs(bestSize - desiredDensity)
        return curDiff < bestDif ? curr : curBest
    })

    const tileWidth = bestDensityOption.tile_size
    const tileHeight = bestDensityOption.tile_size
    
    const sx = Math.round(tileTopLeft.x / tileWidth)
    const sy = Math.round(tileTopLeft.y / tileHeight)
    const sWidth = Math.round(openSeaDragonTileSize.x / tileWidth)
    const sHeight = Math.round(openSeaDragonTileSize.y / tileHeight)
    
    const newTileCtx = copyCtx(ctx, false, true)
    // If IE11 is complaining this drawImage is OOB, don't do it at all.
    if (!window['hasWarnedAnno2ie11'])
        newTileCtx.drawImage(bestDensityOption.canvas, Math.min(bestDensityOption.canvas.width, sx), sy, sWidth, sHeight, 0, 0, newTileCtx.canvas.width, newTileCtx.canvas.height)

    if (sx + sWidth > bestDensityOption.canvas.width && false) {
        // IE11 Smears canvas data if it is drawn OOB, mitigate that by clearing the smeared parts
        newTileCtx.fillStyle = 'white'
        if (sx > bestDensityOption.canvas.width) newTileCtx.fillRect(0, 0, newTileCtx.canvas.width, newTileCtx.canvas.height)
        else {
            const goodUntilX = bestDensityOption.canvas.width - (tileTopLeft.x / tileWidth)
            console.log(newTileCtx.canvas.width, bestDensityOption.canvas.width, (tileTopLeft.x / tileWidth), goodUntilX)
            newTileCtx.fillRect(goodUntilX, 0, newTileCtx.canvas.width - goodUntilX, newTileCtx.canvas.height)
        }
    }

    newTileCtx.fillStyle = ctx.fillStyle
    convertToDensityMask(newTileCtx, colorOptions)
    ctx.drawImage(newTileCtx.canvas, 0, 0)
}


/**
 * Util that replaces black with transparent and other values with better color
 * 
 * @param ctx Canvas to replace the values from
 * @param positiveColor Color used to replace all non-black pixels (r, g, b, [a])
 */
function convertToDensityMask(ctx: CanvasRenderingContext2D, colorOptions: ColorOptions) {
    const imgd = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
    const pix = imgd.data
    
    const fillColor = colorOptions.fillColor

    // Loops through all of the pixels and modifies the components.
    for (let i = 0; i < pix.length; i += 4) {
        // RG and B should be the same because greyscale
        const pixVal = pix[i] / 255
        const relativeIntensity = Math.min(pixVal, 0.9) // Limit between 0 and 0.9

        if (colorOptions.densityFill === 'Turbo') {
            const turboI = Math.floor(relativeIntensity * 255)
            const turboColors = turboColorMap[turboI]

            pix[i] = Math.floor(turboColors[0] * 255) // r
            pix[i + 1] = Math.floor(turboColors[1] * 255) // g
            pix[i + 2] = Math.floor(turboColors[2] * 255) // b
            pix[i + 3] = Math.round(relativeIntensity * 255) // a
        } else if (colorOptions.densityFill === 'fillColor') {
            pix[i] = fillColor.r // r
            pix[i + 1] = fillColor.g // g
            pix[i + 2] = fillColor.b // b
            pix[i + 3] = Math.min(Math.round(relativeIntensity * 255), 180) // a
        } else {
            pix[i + 3] = 0;
        }
    }

    ctx.putImageData(imgd, 0, 0);
}