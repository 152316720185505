import { EfficientArray } from "../common/EfficientArray";
import { PolygonContainer } from "./PolygonContainer";
import { decodePolygonsFromBytes } from "./polygonDecoder/polygonDecoder";
import { EncodedTypedArray, NegativePolygonObj, PackedEffecientArray, MaskArray } from "../types";

/**
 * Revives mask data into a usable format. Extracts the relevant information from a packed format
 * and turns them into suitable JS-based objects.
 * 
 * @param tarBuf Raw buffer containing a TAR file with tile_x_y.png files
 * @returns MaskArray
 */
export function reviveMaskFromTar(tarBuf: Uint8Array) {
    const maskArr: MaskArray = {}
    
    const u8 = new Uint8Array(tarBuf)
    const headerFormat = [[0, 100], [124, 136]] // [file_name, octal file length] 
    let pos = 0
    
    while (pos < u8.byteLength) {
        const header: string = String.fromCharCode.apply(null, u8.slice(pos, pos+136)) // Convert bytes to string
        const [fileName, octalFileLength] = headerFormat.map((offset) => header.slice(offset[0], offset[1]).replace(/\0/g,''))

        if (fileName === "") break;
        const fileNumBytes = parseInt(octalFileLength, 8)
        const fileBuf = u8.slice(pos + 512, pos + 512 + fileNumBytes)
        
        // Save tile in the maskArr
        if (fileName.includes('tile')) {
            // ['x111', 'y108']
            const fileNameParts = fileName.split('tile_')[1].split('.png')[0].split('_')
            const xI = parseInt(fileNameParts[0].slice(1))
            const yI = parseInt(fileNameParts[1].slice(1))
        
            if (maskArr[yI] == null) maskArr[yI] = {}
            maskArr[yI][xI] = fileBuf
        }

        // Move forward the pointer
        pos += 512 + Math.ceil(fileNumBytes / 512) * 512
    }
    return maskArr
}

type TilePolygonIndices = {
    [key: number]: {
        [key: number]: EncodedTypedArray
    }
}

/**
 * Revives polygons data into a usable format. Extracts the relevant information from a packed format
 * and turns them into suitable JS-based objects. Is quite space effecient using a contiguous block of memory.
 * 
 * @returns PolygonContainer
 */
export function polygonReviver(negativePolygons: NegativePolygonObj, tilePolygonIndices: TilePolygonIndices, bigTilePolygonIndices: TilePolygonIndices, encodedPolygons: Uint8Array) {
    const polyCont = new PolygonContainer() 
    // Populate the polygon container
    //    Starting with the tiles information
    for (const yI in tilePolygonIndices) {
        const row = tilePolygonIndices[yI]
        polyCont.allTiles[yI] = {}
        for (const xI in row) {
            polyCont.allTiles[yI][xI] = typedArrayReviver(row[xI]) as Uint32Array
        }
    }

    // Then the big polygon indices
    for (const yI in bigTilePolygonIndices) {
        const row = bigTilePolygonIndices[yI]
        polyCont.bigTiles[yI] = {}
        for (const xI in row) {
            polyCont.bigTiles[yI][xI] = typedArrayReviver(row[xI]) as Uint32Array
        }
    }

    //    And then the polygon efficient Array
    
    polyCont.polygons = decodePolygonsFromBytes(encodedPolygons)
    
    polyCont.negativePolygons = negativePolygons
    polyCont.tileSize = 256
    return polyCont
}

/**
 * Revives a byte buffer into a JS typed array, dropping all references to the original buffer
 * 
 * @param value The encoded typed array, containing the byte buffer and the type of the array (ie. Uint32)
 * @returns A TypedArray
 */
export function typedArrayReviver(value: EncodedTypedArray) {
    // We have an encoded type array for sure now

    const bytesView = value.bytes.slice() // Make a copy of the buffer, so we have a zero based index
    if (value.type === 'uint8') return new Uint8Array(bytesView.buffer, bytesView.byteOffset, value.len)
    else if (value.type === 'uint16') return new Uint16Array(bytesView.buffer, bytesView.byteOffset, value.len)
    else if (value.type === 'uint32') return new Uint32Array(bytesView.buffer, bytesView.byteOffset, value.len)

    // Should never happen
    return null
}

/**
 * Revives an EfficientArray into a JS based representation.  
 * Currently not in use.
 * 
 * @param value The encoded effecient array
 * @returns An EfficientArray
 */
function effecientArrayReviver(value: PackedEffecientArray) {
    const newArr = new EfficientArray()
    newArr.valuesArray = typedArrayReviver(value.data.valuesArray) as Uint32Array
    newArr.offsetArray = typedArrayReviver(value.data.offsetArray) as Uint32Array
    newArr.curOffsetIndex = value.data.length
    return newArr
}
