import { EfficientArray } from "../common/EfficientArray"
import { getTileRange } from "../common/utils"
import { NegativePolygonObj, Polygon } from "../types"

/**
 * Tiles exist of a y key containing x keys, containing an array with the present polygon
 * indices.
 */
type Tiles = {
    [key: number]: {
        [key: number]: Uint32Array
    }
}

export class PolygonContainer {
    allTiles: Tiles = {}
    bigTiles: Tiles = {} // Contains the indices of big polygons in each tile
    polygons: EfficientArray = new EfficientArray()
    negativePolygons: NegativePolygonObj = {}
    tileSize: number = -1

    /**
     * Retrieves a clean list of polygons based on the specified indices  
     * Can be thought of as getting a slice of polygons in a nice format
     * 
     * @param indices Array of polygon indices that need to be retrieved
     * @returns All the polygons at the specified indices
     */
    getPolygonsFromIndices(indices: ArrayLike<number>) {
        const polygons: Polygon[] = []
        for (let i = 0; i < indices.length; i++) {
            const presentPolygonI = indices[i]
            const vertices = this.polygons.getValues(presentPolygonI) as Uint32Array
            if (!vertices) {
                console.warn("No vertices in polygon i", presentPolygonI, this.polygons)
                continue
            }

            const negativePolygonIs = this.negativePolygons[presentPolygonI] ?? []
            
            polygons.push({
                vertices,
                negativePolygons: negativePolygonIs.map(negativeI => this.polygons.getValues(negativeI)) 
            })
        }
        return polygons
    }

    getPolygonsIinTileRange(range: ReturnType<typeof getTileRange>, getOnlyBig: boolean) {
        const tiles = getOnlyBig ? this.bigTiles : this.allTiles

        const allPresentIndices: Set<number> = new Set()
        for (let y = range.y.start; y < range.y.end; y++) {
            for (let x = range.x.start; x < range.x.end; x++) {
                if (!(y in tiles)) continue
                if (!(x in tiles[y])) continue
                const presentIndicesInTile = tiles[y][x]
                presentIndicesInTile.forEach((i) => allPresentIndices.add(i))
            }
        }
        return allPresentIndices
    }
    /**
     * Gets all the polygons in a range of x and y coordinates of tiles
     * 
     * @param range The x and y range of the tiles
     * @param getOnlyBig Boolean to indicate whether to only retrieve the big polygons in the list
     * @returns A list of polygons in the specified range
     */
    getTileRange(range: ReturnType<typeof getTileRange>, getOnlyBig: boolean) {
        const allPresentIndices = this.getPolygonsIinTileRange(range, getOnlyBig)
        return this.getPolygonsFromIndices([...allPresentIndices])
    }
}