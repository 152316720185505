import { hasOverlap } from "../heatmap/heatmapFilter"
import { ContinueFunction, Label } from "../types"
import { addBorderToCanvas, log2canvas, SHOULD_SHOW_STATS } from "../common/utils"

export function labelFilter(
    ctx: CanvasRenderingContext2D,
    next: ContinueFunction,
    tile: OpenSeadragon.Tile,
    labels: Label[]
) {
    ctx.save()
    const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
    const viewport = viewer.viewport
    // Get bounds of this tile
    const vc2ic = viewport.viewportToImageCoordinates.bind(viewport) // function to convert viewport to universal image coordinates
    const tileTopLeft: OpenSeadragon.Point = vc2ic(tile.bounds.getTopLeft())
    const tileSize: OpenSeadragon.Point = vc2ic(tile.bounds.getSize())
    const tileFactor = Math.max(tileSize.x / 256, tileSize.y / 256)

    // @ts-ignore Ignore invocation of OpenSeaDragon global
    const curTileRect: OpenSeadragon.Rect = new OpenSeadragon.Rect(tileTopLeft.x, tileTopLeft.y, tileSize.x, tileSize.y)

    for (const label of labels) {
        label.fontsize = 32 // WARNING: Overwrite all fontsizes, because the saved ones are way to big!

        ctx.font = label.fontsize + 'px Helvetica'; 
        const labelDims = getLabelDims(ctx, label)
        // @ts-ignore Ignore invocation of OpenSeaDragon global
        const labelRect = new OpenSeadragon.Rect(label.x, label.y, (labelDims.width + 6) * tileFactor, labelDims.height * tileFactor)
        if (hasOverlap(curTileRect, labelRect)) drawLabel(ctx, curTileRect, label)
    }

    if (SHOULD_SHOW_STATS) {
        addBorderToCanvas(ctx, 3)
        log2canvas(ctx, `Z ${tile.level} X ${tile.x} Y ${tile.y}`)
    }
    ctx.restore()
    next()
}

function drawLabel(ctx: CanvasRenderingContext2D, tileRect: OpenSeadragon.Rect, label: Label) {
    /** Convert 2 canvas coordinates */
    const xInTile = label.x - tileRect.x
    const yInTile = label.y - tileRect.y

    const xRatioInTile = xInTile / tileRect.width
    const yRatioInTile = yInTile / tileRect.height
    const canvasX = xRatioInTile * ctx.canvas.width
    const canvasY = yRatioInTile * ctx.canvas.height

    const labelDims = getLabelDims(ctx, label)
    const padding = 3
    const labelBoundWidth = labelDims.width + padding * 2
    // First create the semi-transparent white background box
    ctx.fillStyle = '#FFFFFF'
    ctx.globalAlpha = 0.5
    ctx.fillRect(canvasX, canvasY, labelBoundWidth, labelDims.height + padding)
    
    // Then draw lines around this bounding box
    ctx.globalAlpha = 1
    ctx.strokeStyle = '#000000'
    ctx.strokeRect(canvasX, canvasY, labelBoundWidth, labelDims.height + padding)

    // And finally, actually write each line of text
    ctx.fillStyle = '#000000'
    let curY = canvasY + label.fontsize - padding
    for (const line of label.label.split('\n')) {
        ctx.fillText(line, canvasX + padding, curY)
        curY += label.fontsize
    }
}

function getLabelDims(ctx: CanvasRenderingContext2D, label: Label) {
    const lines = label.label.split('\n')
    const height = lines.length * label.fontsize
    const width = lines.reduce((curWidest, line) => { // Get the width of the widest line in the label
        const curLineWidth = ctx.measureText(line).width
        return curLineWidth > curWidest ? curLineWidth : curWidest
    }, 0)
    return { height, width }
}

export function testLabelFilter() {
    console.log('Loading testLabelFilter')
    const labels: Label[] = [{
        label: 'Test 1',
        x: 100,
        y: 100,
        whenToShow: "always",
        fontsize: 16
    }, {
        label: 'Test 2',
        x: 1000,
        y: 1000,
        whenToShow: "always",
        fontsize: 32
    },
    {
        label: 'Test 3 AAAAAAAAAAAAAAA\nBBBBBBB',
        x: 10000,
        y: 10000,
        whenToShow: "always",
        fontsize: 32
    },
    {"label":"TestingABC\nTest123\nABCD","x":2137,"y":14556,"whenToShow":"always","fontsize":391}
    ]

    labels.forEach((e) => e.fontsize = 30)
    const testLabelFilter = function(ctx: CanvasRenderingContext2D, next: ContinueFunction, tile: OpenSeadragon.Tile) {
        return labelFilter(ctx, next, tile, labels)
    }

    window['anno2Manager'].osdOverlayPlugin.updateOverlay(testLabelFilter, 'test-label-filter')
}

