import { SHOULD_SHOW_STATS, addBorderToCanvas, log2canvas, getTileRange, parseColor } from "../common/utils"
import { densityFilter } from "../density/densityFilter"
import { ContinueFunction, LookupTable, MaskData, ColorOptions } from "../types"
import { decodePNG } from "./pngDecoder/decodePNG"

/**
 * Base OpenSeaDragon filter for mask data.
 * Draws either a summary view, encompassed in the "lookupTables", or the raw mask data to a JS canvas.
 * 
 * @param ctx OpenSeaDragon Tile canvas context
 * @param next Function to be called when done with processing to trigger the next filter
 * @param tile The OpenSeadragon.Tile that is being processed by this respective pass
 */
export function maskFilter(
    ctx: CanvasRenderingContext2D,
    next: ContinueFunction,
    tile: OpenSeadragon.Tile,
    lookupTables: LookupTable[],
    maskData: MaskData,
    colorOptions: ColorOptions
) {
    // The next() function must _always_ be called when done
    const t0 = performance.now()
    ctx.save()

    const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
    const viewport = viewer.viewport
    const tileZoom = tile.level

    const fillColor = colorOptions.fillColor
    // Set the base color, the opacity is modified in the drawing functions
    ctx.fillStyle = `rgb(${fillColor.r}, ${fillColor.g}, ${fillColor.b})`
    ctx.globalAlpha = fillColor.a

    // Get base tiles inside this tile
    const vc2ic = viewport.viewportToImageCoordinates.bind(viewport) // function to convert viewport to universal image coordinates
    const tileTopLeft: OpenSeadragon.Point = vc2ic(tile.bounds.getTopLeft())
    const tileBottomRight: OpenSeadragon.Point = vc2ic(tile.bounds.getBottomRight())
    const tileSize: OpenSeadragon.Point = vc2ic(tile.bounds.getSize())

    // Show summary/density view when the tilesize is either fully zoomed in, or one level above that
    const showDensity = !maskData || tileSize.x > 256 * 2 + 1 // Always add one for floating diff
    
    // Draw density lookup tables if user is zoomed out
    if (showDensity) densityFilter(ctx, tileTopLeft, tileBottomRight, tileSize, lookupTables, colorOptions)
    
    // If the user is zoomed in and we have points/mask data
    if (!showDensity && maskData) maskFilterRaw(ctx, tileTopLeft, tileBottomRight, tileSize, maskData)

    const timeTotal = performance.now() - t0
    // Log some stuff to the canvas
    if (SHOULD_SHOW_STATS) {
        addBorderToCanvas(ctx, 3)
        log2canvas(ctx, `Z ${tileZoom} ${timeTotal.toFixed(1)} ms mask`, 0 + 1)
    }
    // continue to the next filter
    ctx.restore()
    next()
}



/**
 * Function to draw the relevant mask (or individual pixel points) on top of an OpenSeaDragon tile 
 * 
 * @param ctx The OpenSeaDragon Tile canvas context
 * @param tileTopLeft The point of the most top left coordinate of the OSD tile
 * @param tileBottomRight The point of the most bottom right coordinate of the OSD tile
 * @param openSeaDragonTileSize The size in image x and y of the OSD tile
 * @param data The points data, being an object containing y and x keys and the data associated with that tile
 */
function maskFilterRaw(
    ctx: CanvasRenderingContext2D,
    tileTopLeft: OpenSeadragon.Point,
    tileBottomRight: OpenSeadragon.Point,
    openSeaDragonTileSize: OpenSeadragon.Point,
    data: MaskData
) {
    // We need to find the offsets of these coordinates in the base tile mask array
    const maskWidth = data.tileWidth
    const maskHeight = data.tileHeight

    const range = getTileRange(tileTopLeft, tileBottomRight, maskWidth, maskHeight)
    // Loop over every tile present in this canvas
    for (let yI = range.y.start; yI < range.y.end; yI++) {
        for (let xI = range.x.start; xI < range.x.end; xI++) {

            const xInTile = (xI - range.x.start) * (maskWidth + 1)
            const yInTile = (yI - range.y.start) * (maskHeight + 1)

            const masks = data.masks
            const hasData = yI in masks && xI in masks[yI]
            const curTileMask = hasData ? masks[yI][xI] : [] // The PNG buf, or raw [x1, y1, x2, y2]
    
            if (curTileMask.length > 0) {
                if (curTileMask[0] === 137 && curTileMask[1] === 80) {
                    // PNG magic bytes, TODO clean up after PNG decision
                    drawPngOverCanvas(curTileMask as Uint8Array, ctx, xInTile, yInTile, openSeaDragonTileSize)
                } else if (curTileMask[4] === 48 && curTileMask[6] === 1) {
                    // JBIG2 magic bytes
                    throw new Error("Got JBIG2 image")
                } else drawRawPoints(ctx, curTileMask, xInTile, yInTile, openSeaDragonTileSize)
            }
        }  // Done row
    } // Done column  
}

/**
 * Colors a pixel on an OpenSeaDragon tile canvas for each passed point using an offset.
 * 
 * @param ctx The canvas on which the pixels are colored
 * @param pointsInTile Flattend list of [x, y] pairs, assumed to be the remainders in the tile
 * @param xInTile X-offset of the openSeaDragonTile in the canvas
 * @param yInTile Y-offset of the openSeaDragonTile in the canvas
 * @param tileSize Size of the OpenSeaDragon tile
 */
function drawRawPoints(
    ctx: CanvasRenderingContext2D, 
    pointsInTile: ArrayLike<number>,
    xInTile: number, 
    yInTile: number, 
    tileSize: OpenSeadragon.Point,
) {
    // Draw a pixel for each point on the canvas
    // Configure the color for each pixel
    ctx.beginPath()
    const origGlobalAlpha = ctx.globalAlpha
    ctx.globalAlpha = 0.7

    for (let pointI = 0; pointI < pointsInTile.length; pointI += 2) {
        const x = pointsInTile[pointI]
        const y = pointsInTile[pointI + 1]

        const xPointInTile = x + xInTile
        const yPointInTile = y + yInTile
        // We need to convert these coordinates to the position in the tile
        const ratioX = xPointInTile / tileSize.x
        const ratioY = yPointInTile / tileSize.y
        const canvasXstart = Math.floor(ratioX * ctx.canvas.width)
        const canvasYstart = Math.floor(ratioY * ctx.canvas.height)
        
        ctx.rect(canvasXstart, canvasYstart, 1, 1) // Draw a single pixel for this point
    }

    // Actually fill the added rectangles
    ctx.fill()
    ctx.closePath()
    ctx.globalAlpha = origGlobalAlpha
}


/**
 * Function that parses PNG bytes and draws them over an OpenSeaDragon tile canvas.
 * 
 * @param imgData PNG byte buffer
 * @param ctx Canvas to draw over
 * @param xInTile X-offset of the PNG in the tile associated with this canvas
 * @param yInTile Y-offset of the PNG in the tile associated with this canvas
 * @param tileSize Size of the associated OpenSeaDragon tile
 */
function drawPngOverCanvas(
    imgData: Uint8Array, 
    ctx: CanvasRenderingContext2D,
    xInTile: number, 
    yInTile: number,
    tileSize: OpenSeadragon.Point,
) {
    const divider = Math.round(tileSize.x / 256)
    const imgSize = Math.round(256 / divider)

    const ratioX = xInTile / tileSize.x
    const ratioY = yInTile / tileSize.y
    const canvasXstart = Math.floor(ratioX * ctx.canvas.width)
    const canvasYstart = Math.floor(ratioY * ctx.canvas.height)

    const pngCanvas = decodePNG(imgData)
    // We need to extract the raw RGB values from fillStyle
    if (typeof ctx.fillStyle !== 'string') throw new Error('Fill style not a string, cannot extract rgb color')
    const fillColor = parseColor(ctx.fillStyle)
    convertToTransparentMask(pngCanvas.getContext('2d'), [fillColor.r, fillColor.g, fillColor.b, ctx.globalAlpha * 255])
    
    ctx.drawImage(pngCanvas, canvasXstart, canvasYstart, imgSize, imgSize)
}

/**
 * Util that replaces black with transparent and other values with a positive color
 * 
 * @param ctx Canvas to replace the values from
 * @param positiveColor Color used to replace all non-black pixels (r, g, b, [a])
 */
function convertToTransparentMask(ctx: CanvasRenderingContext2D, positiveColor: number[]) {
    const imgd = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
    const pix = imgd.data

    // Loops through all of the pixels and modifies the components.
    for (let i = 0; i < pix.length; i += 4) {
        if (pix[i] === 0) pix[i+3] = 0; // black becomes transparent, so set alpha to 0
        else {
            pix[i] = positiveColor[0]
            pix[i + 1] = positiveColor[1]
            pix[i + 2] = positiveColor[2]
            if (positiveColor.length > 3) pix[i + 3] = positiveColor[3]
        }
    }

    ctx.putImageData(imgd, 0, 0);
}
