import 'whatwg-fetch' // Polyfill fetch for IE11
import { decode } from "@msgpack/msgpack";

import { addInfoBox, addMouseTracker } from '../lib/anno2/common/utils';
import { Anno2Manager } from '../lib/anno2/Anno2Manager';


// DO NOT REMOVE: Call decode once, to make sure (?) Babel.js notices and adds the needed polyfills for IE11
console.log("Anno2 loaded", decode(new Uint8Array([128])), Array.from([123]), Uint8Array.from([123]), "MODE:", process.env.NODE_ENV)

// Load the OpenSeaDragon filters once the page is loaded, and add a handler
// to the viewer to reload the filters once opened
$(document).on("viewer-open", () => {
    addInfoBox()
    setTimeout(addMouseTracker, 1000)
    const anno2Manager = new Anno2Manager(window['viewer'])
    window['anno2Manager'] = anno2Manager
})
