// Helpers

/**
 * Reads a single bit from a byte buffer at an offset
 * 
 * @param arr Byte buffer
 * @param offset Offset to read
 * @returns Either 1 or 0
 */
function readBit(arr: Uint8Array, offset: number): 1 | 0 {
  const byteI = Math.floor(offset / 8)
  if (byteI > arr.length - 1) throw new Error('Bit index is more than available in buffer')
  const byte = arr[byteI]
  const mask = 1 << (7 - offset % 8); // gets the nth bit from the left
  return byte & mask ? 1 : 0
}

/**
 * Reads any number bits from a byte buf  
 * WARNING: bits returned as a number, so prefixing zeros are dropped, max 63 bits
 * 
 * @param arr Byte buf
 * @param start start offset to read from
 * @param end end of the offset to read
 * @returns 
 */
function readBits(arr: Uint8Array, start: number, end: number) {
  // TODO can likely be further optimized by reading more than 1 bit at a time
  let num = 0
  for (let offset = start; offset < end; offset++) {
    const i = end - offset - 1
    num += readBit(arr, offset) << i
  }
  return num
}

// Converters
export type OmegaEncodingType = 'naturalOnly' | 'naturalZero' | 'integers'

/**
 * Converts raw numbers into their original numbers using the encoding type
 * 
 * @param num raw number
 * @param type Omega encoding type, natural number, all ints etc.
 * @returns The original number
 */
function finalizeDecodeWithType(num: number, type: OmegaEncodingType) {
  if (type == 'naturalOnly') return num // No op
  else if (type == 'naturalZero') return num - 1
  else if (type == 'integers') {
    // [1, 2, 3, 4, 5] -> [0, 1, -1, 2, -2]
    return Math.ceil(num / 2 * (num % 2 == 0 ? 1 : -1))
  } else throw new Error('Unsupported type')
}

/**
 * Decodes a byte buffer using Omega Elias Encoding  
 * WARNING: Can result in suffixing zero's if the final byte was not filled using encoding
 * 
 * @param encoded Byte buffer of the omega encoded numbers
 * @param type Omega encoding type, natural number, all ints etc.
 * @returns A list of numbers that where encoded
 */
export function omegaDecode(encoded: Uint8Array, type: OmegaEncodingType): number[] {
  const rawNums: number[] = []
  let offset = 0
  const numBitsAvailable = encoded.byteLength * 8

  while (numBitsAvailable > offset) {
    const [newNum, newOffset] = decodeRaw(encoded, 1, offset)
    offset = newOffset
  
    rawNums.push(newNum)
  }

  const nums = rawNums.map((num) => finalizeDecodeWithType(num, type))

  return nums
}

/**
 * Decodes a single raw number from the byte buffer, only to be used by omegaDecode
 * 
 * @param encoded Byte buffer
 * @param num Current raw number
 * @param offset current offset in byte buf
 * @returns The decoded raw number
 */
function decodeRaw(encoded: Uint8Array, num: number, offset: number): [number, number] {
  if (readBit(encoded, offset + 0) == 0) return [num, offset + 1]
  // If the next bit is a "1" then read it plus N more bits, and use that binary number as the new value of N. Go back to Step 2.
  const newOffset = offset + num + 1
  const newNum = readBits(encoded, offset + 0, offset + num + 1)

  return decodeRaw(encoded, newNum, newOffset)
}