import { copyCtx, log } from "./utils"

export class OSDOverlayPlugin {
    viewer: OpenSeadragon.Viewer = null
    allOverlays: {[key: string]: Function} = {}
    disabledOverlays: string[] = []

    constructor (viewer: OpenSeadragon.Viewer) {
        this.viewer = viewer
        this.#addHandlers()
    }

    /**
     * Add event handler to OSD to trigger the overlays when needed
     */
    #addHandlers() {
        // Apply the overlay handler
        this.viewer.addHandler('tile-drawing', (ev) => {
            const cacheRecord = (this.viewer as any).tileCache.getImageRecord(ev.tile.cacheKey)
            const renderedCtx = ev.rendered as unknown as CanvasRenderingContext2D
            
            // Store the downloaded image if not done before
            if (!cacheRecord.origCtx) cacheRecord.origCtx = copyCtx(renderedCtx)
            // Calculate the newCtx if not done before
            if (!cacheRecord.newCtx) {
                const newCtx = copyCtx(cacheRecord.origCtx, true, true)
                // Apply overlays
                for (const overlay of this.inUseOverlays) {
                    try {
                        overlay(newCtx, () => {}, ev.tile)
                   } catch(err) {
                        console.warn('Caught error:', overlay, err)
                        // Check if IE11 and not already warned
                        if (typeof window['CollectGarbage'] == 'function' && !window['hasWarnedAnno2ie11']) {
                            window['hasWarnedAnno2ie11'] = true
                            alert('Failed loading annotation due to Internet Explorer 11, please use a more modern browser.')
                        }
                   }
                }
                // Save the result
                cacheRecord.newCtx = newCtx
                const t0 = performance.now()
                // Draw the overlays over the rendered Ctx
                renderedCtx.drawImage(newCtx.canvas, 0, 0)
                if (performance.now() - t0 > 100) log('Drawing rend ctx', (performance.now() - t0).toFixed(1), cacheRecord.newCtx.canvas)
            }
        })
    }

    /**
     * Clear the OSD tile cache, and forces a redrawing of the tiles and overlays
     */
    forceReload() {
        if (this.viewer !== window['viewer'])
            return; // viewer changed or went away, don't draw the anno2
        const viewer = this.viewer as any
    
        for (const key in viewer.tileCache._imagesLoaded) {
            viewer.tileCache._imagesLoaded[key].newCtx = null
        }
        viewer.forceRedraw()
    }

    /**
     * Update or add a overlay that runs for each OSD tile
     * 
     * @param newOverlay Function that is called for each OSD tile that is loaded
     * @param key an associated key with this function
     */
    updateOverlay(newOverlay: Function, key: string) {
        this.allOverlays[key] = newOverlay
        const overlayIsEnabled = !this.disabledOverlays.includes(key)
        if (overlayIsEnabled) this.forceReload()
    }

    /**
     * Get a function that can disable a overlay to run for each OSD tile
     * 
     * @param key Which function to disable
     * @returns A function that can disable a overlay
     */
    getOverlayDisabler(key: string) {
        const curOverlayInstance = this
        return function () {
            if (curOverlayInstance.disabledOverlays.includes(key)) return

            curOverlayInstance.disabledOverlays.push(key)
            curOverlayInstance.forceReload()
        }
    }

    /**
     * Get a function that can be called to enable a overlay after it has been disabled
     * 
     * @param key Which function to enable
     * @returns A function that enable a overlay
     */
    getOverlayEnabler(key: string) {
        const curOverlayInstance = this
        return function () {
            if (!curOverlayInstance.disabledOverlays.includes(key)) return

            curOverlayInstance.disabledOverlays = curOverlayInstance.disabledOverlays
                .filter(item => item !== key)
            curOverlayInstance.forceReload()
        }
    }

    get inUseOverlays() {
        const inUseOverlays: Function[] = []
        for (const [key, value] of Object.entries(this.allOverlays)) {
            if (!this.disabledOverlays.includes(key)) inUseOverlays.push(value)
        }
        return inUseOverlays
    }
}