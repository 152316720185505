 // 4 KiB is pretty fast, as we don't trigger buffer increases very often, also a common page size
const ONE_MB = 1024 * 1024
const STEP_SIZE = 5 * ONE_MB

/**
 * Class that can effeciently store arrays of integers in a continuous block of memory
 */
export class EfficientArray {
    valueArrayConstructor: Uint32ArrayConstructor = Uint32Array
    valuesArray: Uint32Array = new Uint32Array(new ArrayBuffer(ONE_MB))
    offsetArray = new Uint32Array(new ArrayBuffer(ONE_MB))
    curOffsetIndex = 0

    constructor (valueArrayConstructor?: Uint32ArrayConstructor) {
        if (valueArrayConstructor) {
            this.valueArrayConstructor = valueArrayConstructor
            this.valuesArray = new valueArrayConstructor(new ArrayBuffer(ONE_MB))
        }

    }

    #increaseValuesBufferSize(numNewElements: number) {
        const numBytesNeeded = numNewElements * this.valuesArray.BYTES_PER_ELEMENT
        let stepSize = this.valuesArray.byteLength > 20 * ONE_MB ? 20 * ONE_MB : STEP_SIZE

        const numNewBytes = Math.ceil(numBytesNeeded / stepSize) * stepSize
        const newBuf = new ArrayBuffer(this.valuesArray.byteLength + numNewBytes)
        const newView = new this.valueArrayConstructor(newBuf)
        newView.set(this.valuesArray)
        this.valuesArray = newView
    }

    #increaseOffsetsBufferSize() {
        const newBuf = new ArrayBuffer(this.offsetArray.byteLength + STEP_SIZE)
        const newView = new Uint32Array(newBuf)
        newView.set(this.offsetArray)
        this.offsetArray = newView
    }

    /**
     * Stores list of numbers 
     * 
     * @param values Array of numbers, all numbers must be >= 0 and less than 2^^16
     */
    addValues(values: ArrayLike<number>) {
        const offset = this.offsetArray[this.curOffsetIndex]

        // Make sure we stay within bounds of the ArrayBuffer, and extend it if needed
        if (offset + values.length + 1 > this.valuesArray.length) this.#increaseValuesBufferSize(values.length)
        if (this.curOffsetIndex + 1 >= this.offsetArray.length) this.#increaseOffsetsBufferSize()

        // Add the values and store the new offset
        for (let i = 0; i < values.length; i++) this.valuesArray[offset + i] = values[i]
        this.offsetArray[++this.curOffsetIndex] = offset + values.length
    }

    /**
     * Retrieves a list of numbers
     * 
     * @param i Index of the wanted number array
     * @returns Mutable Uint32Array | Uint8Array
     */
    getValues(i: number) {
        if (i >= this.curOffsetIndex) return undefined
        const start = this.offsetArray[i]
        const end = this.offsetArray[i + 1]
        return this.valuesArray.subarray(start, end)
    }

    get length() {
        return this.curOffsetIndex
    }

    get numValues() {
        return this.offsetArray[this.curOffsetIndex]
    }
}
