import { decodePNG } from "../mask/pngDecoder/decodePNG";
import { turboColorMap } from "../common/turboColorMap";
import { ColorOptions, ContinueFunction, HeatmapData } from "../types"
import { addBorderToCanvas, log2canvas, SHOULD_SHOW_STATS } from "../common/utils";

/**
 * OpenSeaDragon tile based filter to draw a heatmap/any greyscale image in a OSD tile-based manner.
 * 
 * @param ctx Tile rendering context
 * @param next Function that gets called when done
 * @param tile Current OpenSeadragon tile object
 * @param heatmapData Data that is used to (potentially) overlay this tile. Not modified.
 */
export function heatmapFilter(
    ctx: CanvasRenderingContext2D,
    next: ContinueFunction,
    tile: OpenSeadragon.Tile,
    heatmapData: HeatmapData
) {
    ctx.save()
    // First do some calculations on where the location of this tile is in the WSI, and it's zoom level and size etc.
    const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
    const viewport = viewer.viewport
    // Get bounds of this tile
    const vc2ic = viewport.viewportToImageCoordinates.bind(viewport) // function to convert viewport to universal image coordinates
    const tileTopLeft: OpenSeadragon.Point = vc2ic(tile.bounds.getTopLeft())
    const tileSize: OpenSeadragon.Point = vc2ic(tile.bounds.getSize())

    // @ts-ignore Ignore invocation of OpenSeaDragon global
    const curTileRect: OpenSeadragon.Rect = new OpenSeadragon.Rect(tileTopLeft.x, tileTopLeft.y, tileSize.x, tileSize.y)

    // @ts-ignore Ignore invocation of OpenSeaDragon global
    const heatMapRect: OpenSeadragon.Rect = new OpenSeadragon.Rect(
        heatmapData.x, heatmapData.y,
        heatmapData.img.canvas.width * heatmapData.sizePerPixel,
        heatmapData.img.canvas.height * heatmapData.sizePerPixel
    )

    // Now copy over a part of this heatmap to the OpenSeaDragonCTX
    if (hasOverlap(curTileRect, heatMapRect)) {
        drawHeatmap(heatmapData.img.canvas, ctx, curTileRect, heatMapRect)
    }

    if (SHOULD_SHOW_STATS) {
        addBorderToCanvas(ctx, 3)
        log2canvas(ctx, `Z ${tile.level} X ${tile.x} Y ${tile.y}`)
    }
    ctx.restore()
    next()
}

/**
 * Draws a part of the heatmap in a tile. Uses some calculations to determine which part
 * to copy over, to which part of the tile.
 * See https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
 * for the relevant parameters we are calculating.
 * 
 * @param heatmap Canvas of a image that will get overlayed on this tile
 * @param tileCtx The Rendering context of the tile that will get modified
 * @param tileBoundBox The bounding box of this tile, in most zoomed in coordinates
 * @param heatmapBoundBox The bounding box of the entire heatmap, in most zoomed in coordinates
 * @param pixelated Whether to draw big "pixels" or smooth them, pixels are more accuracte and look better to me
 */
function drawHeatmap(
    heatmap: HTMLCanvasElement,
    tileCtx: CanvasRenderingContext2D,
    tileBoundBox: OpenSeadragon.Rect,
    heatmapBoundBox: OpenSeadragon.Rect,
    pixelated = true
) {
    // We simply need to calculate all the relevant parameters

    // First calculate the ratios this tile is compared to the entire heatmap
    // And add bounds for the tiles that are bigger than the heatmap, or at the edges
    const ratioX = (tileBoundBox.x - heatmapBoundBox.x) / heatmapBoundBox.width
    const boundRatioX = Math.max(Math.min(ratioX, 1), 0)
    const ratioY = (tileBoundBox.y - heatmapBoundBox.y) / heatmapBoundBox.height
    const boundRatioY = Math.max(Math.min(ratioY, 1), 0)
    const ratioWidth = tileBoundBox.width / heatmapBoundBox.width
    const boundRatioWidth = Math.max(Math.min(ratioWidth, 1), 0)
    const ratioHeight = tileBoundBox.height / heatmapBoundBox.height
    const boundRatioHeight = Math.max(Math.min(ratioHeight, 1), 0)

    // Calculate the place in the heatmap that we should extract this tile from
    const sourceX = Math.round(boundRatioX * heatmap.width)
    const sourceY = Math.round(boundRatioY * heatmap.height)

    const sWidth = Math.round(boundRatioWidth * heatmap.width)
    const sHeight = Math.round(boundRatioHeight * heatmap.height)

    // Calculate where in this tile we should place the heatmap. (0, 0) except for big tiles and the edges
    const destX = Math.max(Math.round(((heatmapBoundBox.x - tileBoundBox.x) / tileBoundBox.width) * tileCtx.canvas.width), 0)
    const destY = Math.max(Math.round(((heatmapBoundBox.y - tileBoundBox.y) / tileBoundBox.height) * tileCtx.canvas.height), 0)
    
    // Calculate the size the heatmap is compared to the tile, (256, 256) except for the big tiles and edges
    const destWidth = Math.round(Math.min((heatmapBoundBox.width / tileBoundBox.width), 1) * tileCtx.canvas.width)
    const destHeight = Math.round(Math.min((heatmapBoundBox.height / tileBoundBox.height), 1) * tileCtx.canvas.height)
    
    if (SHOULD_SHOW_STATS) console.log({intermidiate: {ratioX, ratioY, ratioWidth, ratioHeight, tileBoundBox, heatmapBoundBox}, res: {heatmap, sourceX, sourceY, sWidth, sHeight, destX, destY, destWidth, destHeight}})

    if (pixelated) tileCtx.imageSmoothingEnabled = false;
    tileCtx.drawImage(heatmap, sourceX, sourceY, sWidth, sHeight, destX, destY, destWidth, destHeight)
}

export function calcHeatmapCanvas(pngBytes: ArrayBuffer, colorOptions: ColorOptions) {
    const minValue = colorOptions.threshold != null ? colorOptions.threshold : 1 
    // Load canvas
    const heatmapCanvas = decodePNG(new Uint8Array(pngBytes), 1)
    const heatmapCtx = heatmapCanvas.getContext('2d')
    if (colorOptions.densityFill === 'Turbo') mapColorTurbo(heatmapCtx, colorOptions.fillColor.a * 255, minValue)
    else mapColor(heatmapCtx, [colorOptions.fillColor.r, colorOptions.fillColor.g, colorOptions.fillColor.b, colorOptions.fillColor.a * 255], minValue)
    return heatmapCanvas
}

/**
 * Util that maps a grayscale image, into a "grayscale" colored image.
 * 
 * @param ctx Canvas to replace the values from
 * @param positiveColor Color used to calculate the new pixels (r, g, b, [a])
 * @param minValue Threshold value for drawing, below this it is not rendered
 */
function mapColor(ctx: CanvasRenderingContext2D, color: number[], minValue: number) {
    const imgd = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
    const pix = imgd.data

    // Loops through all of the pixels and modifies the components.
    for (let i = 0; i < pix.length; i += 4) {
        const pixelIntensity = pix[i] / 255 // How white this pixel is from 0-1
        pix[i] = Math.round(color[0] * pixelIntensity)
        pix[i + 1] = Math.round(color[1] * pixelIntensity)
        pix[i + 2] = Math.round(color[2] * pixelIntensity)
        
        const passedAlpha = color.length > 3 ? color[3] : 255
        const alpha = pixelIntensity * 255 <= minValue ? 0 : passedAlpha
        pix[i + 3] = alpha
    }

    ctx.putImageData(imgd, 0, 0);
}

/**
 * Util that maps a grayscale image, into a Turbo colored image.
 * 
 * @param ctx Canvas to replace the values from
 * @param positiveColor Color used to calculate the new pixels (r, g, b, [a])
 */
function mapColorTurbo(ctx: CanvasRenderingContext2D, passedAlpha = 255, minValue: number) {
    const imgd = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
    const pix = imgd.data

    // Loops through all of the pixels and modifies the components.
    for (let i = 0; i < pix.length; i += 4) {
        const pixelIntensity = pix[i]
        const turboI = Math.floor(pix[i])
        const turboColors = turboColorMap[turboI]

        pix[i] = Math.floor(turboColors[0] * 256)
        pix[i + 1] = Math.floor(turboColors[1] * 256)
        pix[i + 2] = Math.floor(turboColors[2] * 256)
        
        const alpha = pixelIntensity <= minValue ? 0 : passedAlpha
        pix[i + 3] = alpha
    }

    ctx.putImageData(imgd, 0, 0);
}

/**
 * Checks if 2 rectangles overlap
 * 
 * @param rectA First rectangle
 * @param rectB Second rectangle
 * @returns Whether there is any overlap in rectangles
 */
export function hasOverlap(rectA: OpenSeadragon.Rect, rectB: OpenSeadragon.Rect) {
    // Calculate the coordinates of the edges of the rectangles
    const r1Left = rectA.x;
    const r1Right = rectA.x + rectA.width;
    const r1Top = rectA.y;
    const r1Bottom = rectA.y + rectA.height;

    const r2Left = rectB.x;
    const r2Right = rectB.x + rectB.width;
    const r2Top = rectB.y;
    const r2Bottom = rectB.y + rectB.height;

    // Check for overlap
    if (
        r1Left < r2Right &&
        r1Right > r2Left &&
        r1Top < r2Bottom &&
        r1Bottom > r2Top
    ) {
        return true; // Rectangles overlap
    } else {
        return false; // Rectangles do not overlap
    }
}