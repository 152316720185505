import { fromImageData, PNG } from './png_decoder';

async function getImageFromURL(url: string) {
    const img = new Image();
    await new Promise(r => (img.onload=r, img.src=url));
    return img
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export function decodePNG(pngBytes: Uint8Array, pixelsPerByte = 8) {
    const pngData = new PNG(pngBytes) as any
    const canvas = document.createElement('canvas')

    const data: Uint8Array = pngData.decodePixels()
    
    canvas.width = pngData.width
    canvas.height = pngData.height

    const ctx = canvas.getContext('2d', { willReadFrequently: true })
    const imgd = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const pix = imgd.data

    // Loops through all of the pixels and modifies the components.
    for (let i = 0; i < pix.length; i += 4) {
        const pixelI = i / 4 // RGBA -> W
        let color = 255
        const dataPixelByteI = Math.floor(pixelI / pixelsPerByte) // 8 pixels per byte
        const pixelByte = data[dataPixelByteI]

        if (pixelsPerByte === 8) { // 1 bit image
            const inByteI = 7 - pixelI % 8
            // use a mask to get the n'th bit in the byte and check if it is set
            const isBitSet = (pixelByte & (1 << inByteI)) == 0
    
            color = isBitSet ? 0 : 255
        } else color = pixelByte

        pix[i] = color // r
        pix[i + 1] = color // g
        pix[i + 2] = color // b
        pix[i + 3] = 255; // alpha
    }

    ctx.putImageData(imgd, 0, 0)
    return canvas
}