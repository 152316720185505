import { SHOULD_SHOW_STATS, addBorderToCanvas, log2canvas, getTileRange } from "../common/utils"
import { densityFilter } from "../density/densityFilter"
import { decodePNG } from "../mask/pngDecoder/decodePNG"
import { ContinueFunction, MaskData, ColorOptions, LookupTable } from "../types"

/**
 * Base OpenSeaDragon filter for circle/points data.
 * Draws circles based on the data
 * 
 * @param ctx OpenSeaDragon Tile canvas context
 * @param next Function to be called when done with processing to trigger the next filter
 * @param tile The OpenSeadragon.Tile that is being processed by this respective pass
 * @param circlesData MaskData containing an tile tree with PNG's or raw points
 * @param colorOptions With which color to draw the circles, uses the fillColor and lineWidth for fixed size points
 * @param numItems The number of points in total, if <= 10000, then no density view is used
 */
export function circleFilter(
    ctx: CanvasRenderingContext2D,
    next: ContinueFunction,
    tile: OpenSeadragon.Tile,
    lookupTables: LookupTable[],
    circlesData: MaskData,
    colorOptions: ColorOptions,
    numItems: number | null
) {
    // The next() function must _always_ be called when done
    const t0 = performance.now()
    ctx.save()

    const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
    const viewport = viewer.viewport
    const tileZoom = tile.level

    const fillColor = colorOptions.fillColor
    // Set the base color, the width is determined in circleFilterRaw
    ctx.strokeStyle = `rgb(${fillColor.r}, ${fillColor.g}, ${fillColor.b})`
    ctx.globalAlpha = fillColor.a

    // Get base tiles inside this tile
    const vc2ic = viewport.viewportToImageCoordinates.bind(viewport) // function to convert viewport to universal image coordinates
    const tileTopLeft: OpenSeadragon.Point = vc2ic(tile.bounds.getTopLeft())
    const tileSize: OpenSeadragon.Point = vc2ic(tile.bounds.getSize())
    
    // @ts-ignore Ignore invocation of OpenSeaDragon global
    const curTileRect: OpenSeadragon.Rect = new OpenSeadragon.Rect(tileTopLeft.x, tileTopLeft.y, tileSize.x, tileSize.y)

    const shouldShowDensity = curTileRect.width > 4096 - 1 && (numItems ?? 10001) > 10 * 1000
    if (shouldShowDensity) densityFilter(ctx, tileTopLeft, curTileRect.getBottomRight(), tileSize, lookupTables, colorOptions)
    else if (circlesData) circleFilterRaw(ctx, curTileRect, colorOptions.fixedLineWidth, circlesData)

    const timeDraw = performance.now() - t0
    ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height) // Call getImageData to make sure we actually have drawn everything (needed in chrome)
    const timeTotal = performance.now() - t0
    // Log some stuff to the canvas
    if (SHOULD_SHOW_STATS) {
        addBorderToCanvas(ctx, 3)
        log2canvas(ctx, `Z ${tileZoom} ${timeDraw.toFixed(1)} / ${timeTotal.toFixed(1)} ms circles`, 0)
    }
    // continue to the next filter
    ctx.restore()
    next()
}


/**
 * Function to draw circles data on top of the relevant tiles
 * 
 * @param ctx The Rendering context of the tile that will get modified
 * @param tileBoundBox The bounding box of this tile, in most zoomed in coordinates
 * @param fixedCircleSize Diameter of a circle in level0 pixels
 * @param data The circles data
 */
function circleFilterRaw(
    ctx: CanvasRenderingContext2D,
    tileBoundBox: OpenSeadragon.Rect,
    fixedCircleSize: number | null,
    data: MaskData
) {
    // We need to find the offsets of these coordinates in the base tile mask array
    const maskWidth = data.tileWidth
    const maskHeight = data.tileHeight

    // (20 level0 / 2) / (512 level0width / 256 canvasWidth) = 5 canvasPixels (radius)
    const tileCircleRadius = fixedCircleSize ? (fixedCircleSize / 2) / (tileBoundBox.width / ctx.canvas.width) : 8
    ctx.save()
    

    if (tileCircleRadius < 2) ctx.lineWidth = 0.5
    else if (tileCircleRadius < 5) ctx.lineWidth = 1
    else ctx.lineWidth = 2

    const range = getTileRange(tileBoundBox.getTopLeft(), tileBoundBox.getBottomRight(), maskWidth, maskHeight)
    const tileDelta = Math.ceil((fixedCircleSize ?? 8) / maskWidth)
    let numCircles = 0
    // Loop over every mask tile present in this canvas, and add a delta to every axis,
    // to also draw the points that are in the neigbouring tiles we add a delta, some neighbouring tiles
    // might have circles that overlap, and need to be visible and drawn
    for (let yI = (range.y.start - tileDelta); yI < (range.y.end + tileDelta); yI++) {
        for (let xI = (range.x.start - tileDelta); xI < (range.x.end + tileDelta); xI++) {

            const tileXoffset = xI * maskWidth
            const tileYoffset = yI * maskHeight

            const masks = data.masks
            const hasData = yI in masks && xI in masks[yI]
            const curTileMask = hasData ? masks[yI][xI] : [] // The PNG buf, or raw [x1, y1, x2, y2]
            
            if (curTileMask.length > 0) {
                ctx.beginPath() // Start a path for every tile
                let rawPoints: ArrayLike<number> = [] // [x1, y1, x2, y2 ...]

                if (curTileMask[0] === 137 && curTileMask[1] === 80) {
                    // If it is a PNG, decode it and save the points that it contains in memory
                    rawPoints = png2rawPoints(curTileMask as Uint8Array)
                    masks[yI][xI] = rawPoints
                } else rawPoints = curTileMask

                // Loop over all points/circles and add an arc/circle at their location
                for (let i = 0; i < rawPoints.length; i += 2) {
                    const imgX = tileXoffset + rawPoints[i + 0]
                    const imgY = tileYoffset + rawPoints[i + 1]
                    const canvasX = ((imgX - tileBoundBox.x) / tileBoundBox.width) * ctx.canvas.width
                    const canvasY = ((imgY - tileBoundBox.y) / tileBoundBox.height) * ctx.canvas.height
                    
                    // Move the pen to the current circle
                    // see https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Drawing_shapes#moving_the_pen
                    ctx.moveTo(canvasX + tileCircleRadius, canvasY)
                    
                    // Actually draw the circle
                    // if the circle is really small, actually draw a rectangle, because it is faster
                    if (tileCircleRadius < 1) ctx.rect(canvasX, canvasY, tileCircleRadius, tileCircleRadius) 
                    else ctx.arc(canvasX, canvasY, tileCircleRadius, 0, 2 * Math.PI)
                    numCircles++
                }
                ctx.stroke(); // Actually draw the path containing the circles
            }
        }  // Done row
    } // Done column  
    
    ctx.restore()

    if (SHOULD_SHOW_STATS) log2canvas(ctx, `${numCircles} circles`, 1)   
}

/**
 * Util that converts a PNG to a list of coordinates of it's white pixels
 * 
 * @param pngData Buffer containing PNG bytes
 * @returns TypedArray with the raw xy coordinates of white pixels, like [x1, y1, x2, y2, etc.]
 */
function png2rawPoints(pngData: Uint8Array) {
    const pngCanvas = decodePNG(pngData)
    const pngCtx = pngCanvas.getContext('2d')
    // Loop over the canvas, to get the coordinates of the white pixels, which represent the circle coordinates
    const imgd = pngCtx.getImageData(0, 0, pngCanvas.width, pngCanvas.height)
    const pix = imgd.data
    const maxNumPoints = pngCanvas.width * pngCanvas.height
    
    const isBigCanvas = pngCanvas.width > 256 || pngCanvas.height > 256
    const points = isBigCanvas ? new Uint32Array(maxNumPoints) : new Uint8Array(maxNumPoints) // Should be ~256 KiB at most when 256x256
    let pointsLen = 0
    // Loops through all of the pixels and modifies the components.
    for (let i = 0; i < pix.length; i += 4) {
        if (pix[i] === 0) continue
        const pixI = Math.floor(i / 4)
        const x = pixI % pngCanvas.width
        const y = Math.floor(pixI / pngCanvas.width)
        points[pointsLen++] = x
        points[pointsLen++] = y
    }

    return points.slice(0, pointsLen) // Snip empty array to reduce size
}
