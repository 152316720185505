// Tiling utils

import { Color, ColorOptions } from "../types"

export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'

/**
 * Returns a range of x and y tile coordinates that contain the tileTopLeft and tileBottomRight
 * 
 * @param tileTopLeft Point containing the image x and y coordinates of the most top left corner
 * @param tileBottomRight Point containing the image x and y coordinates of the most bottom right corner
 * @param tileWidth Width of the tile (ie. 256)
 * @param tileHeight Height of the tile (also 256)
 * @returns 
 */
export function getTileRange(tileTopLeft: OpenSeadragon.Point, tileBottomRight: OpenSeadragon.Point, tileWidth: number, tileHeight: number) {
    return {
        x: {
            start: Math.floor(Math.round(tileTopLeft.x) / tileWidth),
            end: Math.round(tileBottomRight.x) / tileWidth,
        },
        y: {
            start: Math.floor(Math.round(tileTopLeft.y) / tileHeight),
            end: Math.round(tileBottomRight.y) / tileHeight
        }
    }
}


// Canvas utils
/**
 * Utility to paint lines over a canvas, used for debugging
 * 
 * @param ctx Canvas to paint over
 */
export function drawLinesOverCanvas(ctx: CanvasRenderingContext2D) {
    ctx.save()
    
    const height = ctx.canvas.height
    const width = ctx.canvas.width
    const lineWidth = 2
    ctx.lineWidth = lineWidth

    for (let i = 0; i < Math.floor(255 / 2); i += lineWidth) {
        const curH = height - i * 2
        const curW = width - i * 2
        ctx.beginPath()
        ctx.rect(i, i, curW, curH)
        const rgb = [0, 0, 0]
        rgb[(i / lineWidth) % 3] = 255
        ctx.strokeStyle = `rgba(${rgb.join(',')}, 1)`
        ctx.stroke()
        ctx.closePath()
    }
    
    ctx.restore()
}

/**
 * Utility to print text in the top a canvas
 * 
 * @param ctx Canvas to print the text in
 * @param text Text to print
 * @param y Y level to print text add, to not overlap previously written text
 */
export function log2canvas(ctx: CanvasRenderingContext2D, text: string, y = 0) {
    const yOffset = y * 30
    ctx.save()
    ctx.beginPath()
    
    ctx.rect(0, yOffset, ctx.canvas.width, 30)
    ctx.fillStyle = `rgba(255, 255, 255, 0.5)`
    ctx.fill()

    ctx.fillStyle = `rgba(0, 0, 0, 1)`
    ctx.font = "20px Verdana";
    ctx.fillText(text, 5, yOffset + 25)
    
    ctx.closePath()
    ctx.restore()
}

/**
 * Utility to add a translucent border to each edge of a canvas
 * 
 * @param ctx Canvas to add the border to
 * @param width Width of the border
 */
export function addBorderToCanvas(ctx: CanvasRenderingContext2D, width=1) {
    // Adds a red border to the canvas edge
    ctx.beginPath()
    ctx.lineWidth = width
    ctx.strokeStyle = `rgba(0, 255, 0, 0.4)`
    ctx.rect(0, 0, ctx.canvas.width, ctx.canvas.height)
    ctx.stroke()
    ctx.closePath()
}



// UI / UX utils
export let SHOULD_SHOW_STATS = isDev

/**
 * UI Utility to add a box containing the current mouse position over the OpenSeadragon Viewer
 * to a box in the bottom right. Usefull for debugging.
 */
export const addMouseTracker = isDev ? addMouseTrackerRaw : () => {}
export function addMouseTrackerRaw() {
    const OpenSeadragon = window.OpenSeadragon

    const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
    
    const mousePosDiv = document.createElement('div')
    mousePosDiv.style.cssText = 'position: fixed;z-index: 2;right: 10px;bottom: 60px;background: rgba(255,255,255,0.8);'
    document.body.appendChild(mousePosDiv)

    const tracker = new OpenSeadragon.MouseTracker({
        element: viewer.container,
        moveHandler: function(event: any) {
            const webPoint = event.position
            const viewportPoint = viewer.viewport.pointFromPixel(webPoint)
            const imagePoint = viewer.viewport.viewportToImageCoordinates(viewportPoint)
            const zoom = viewer.viewport.getZoom(true)
            const imageZoom = viewer.viewport.viewportToImageZoom(zoom)

            mousePosDiv.innerHTML = 'Web:<br>' + webPoint.toString() + 
                '<br><br>Viewport:<br>' + viewportPoint.toString() +
                `<br><br>Image:<br> (${imagePoint.x.toFixed(0)}, ${imagePoint.y.toFixed(0)})` + 
                '<br><br>Zoom:<br>' + imageZoom.toFixed(2)
        }
    })
    tracker.setTracking(true)
}

/**
 * UI Utility to add a white box to the right hand side of the browser that is used for logging and toggling some settings.  
 * Usefull for debbuging purposes.
 */
export const addInfoBox = isDev ? addInfoBoxRaw : () => {}
export function addInfoBoxRaw() {
    const isDevEnv = document.title.includes('DEV')
    const infoDiv = document.createElement('div')
    log('infodiv', infoDiv)
    infoDiv.style.cssText = 'position: fixed;z-index: 2;right: 10px;bottom: 350px;background: rgba(255,255,255,0.8);'
    if (!isDevEnv) infoDiv.style.cssText += 'display: none;'
    document.body.appendChild(infoDiv)

    infoDiv.innerHTML = '<br>Toggle stats:<br>'
    const statsInput = document.createElement("input");
    statsInput.type = "checkbox";
    if (SHOULD_SHOW_STATS) statsInput.setAttribute('checked', 'true')

    statsInput.addEventListener('change', function() {
        const viewer = ((window as any).viewer as OpenSeadragon.Viewer)
        SHOULD_SHOW_STATS = statsInput.checked
        viewer.world.resetItems()
    })

    infoDiv.appendChild(statsInput); // put it into the DOM

    const fpsDiv = document.createElement("div");
    // Add FPS counter
    (() => {
        let before = performance.now()
        const fpses: number[] = []
        function logFPS(){
            const now = performance.now()
            const fps = Math.round(1000/(now-before))
            if (fpses.length > 15) fpses.shift()
            fpses.push(fps)
            const minFps = fpses.reduce((prevMin, cur) => prevMin < cur ? prevMin : cur)
            before = now
            fpsDiv.innerText = minFps.toFixed(1) + ' FPS'
            requestAnimationFrame(logFPS)
        }
        requestAnimationFrame(logFPS);
    })()
    infoDiv.appendChild(fpsDiv); // put it into the DOM

    const infoDivText = document.createElement('p')
    infoDivText.id = 'infoDivText'
    infoDiv.appendChild(infoDivText);
}

const LOGGED_2_SCREEN: string[] = []
const t0 = performance.now()

/**
 * Logs a string to the infobox on the right of the screen.  
 * If the infobox is not present, the string is alerted.
 * No-op when not in dev
 * 
 * @param text Text to log
 */
export const log2screen = isDev ? log2screenRaw : () => {}
function log2screenRaw(text: string) {
    const msPassed = (performance.now() - t0).toFixed(1)

    LOGGED_2_SCREEN.push(`${msPassed}ms:  ${text}`)
    if (LOGGED_2_SCREEN.length > 15) LOGGED_2_SCREEN.shift()

    const infoDivText = document.getElementById('infoDivText')
    if (!infoDivText) return alert('Could not log to infoDivText: \n' + text)

    infoDivText.innerText = LOGGED_2_SCREEN.join('\n') 
}

export const DEFAULT_COLOR_POLYGONS: Color = {
    r: 0,
    g: 255,
    b: 255,
    a: 1
}

export const DEFAULT_COLOR_MASK: Color = {
    r: 255,
    g: 255,
    b: 0,
    a: 1
}

/**
 * Utility to get the state of a promise.
 * 
 * @param p Any promise
 * @returns A string with the state of the passed promise
 */
export function promiseState(p: any): Promise<"pending" | "fulfilled" | "rejected"> {
    // Returns whether a promise is pending, fullfilled, or rejected
    const t = {};
    return Promise.race([p, t])
      .then(v => (v === t)? "pending" : "fulfilled", () => "rejected");
}

/**
 * Util to log to the console, if in Development.
 * 
 * @param args Arguments to be logged to the console.
 */
export const log = isDev ? console.log : () => {}

/**
 * Chunks any array into a specified size.  
 * Like `[1, 2, 3, 4] => [[1, 2], [3, 4]]` if size == 2
 * 
 * @param arr Array to chunk
 * @param size Size of each of chunks
 * @returns A chunked array
 */
export function chunkArr<Type>(arr: Type[] | Uint32Array | Uint8Array, size: number): Type[][] {
    const chunkedArr = [];
    for(let i = 0; i < arr.length; i += size) {
        chunkedArr.push(arr.slice(i, i+size))
    }
    return chunkedArr
}

/**
 * Copies a canvas context, so that it can be freely modified without changing the original.
 * 
 * @param origCtx Context that is copied from
 * @param copyOverImage Whether to also copy the image data
 * @param willReadFrequently Make the data CPU-bound instead of GPU-bound
 * @returns Context of a new canvas
 */
export function copyCtx(origCtx: CanvasRenderingContext2D, copyOverImage = true, willReadFrequently = false) {
    const canvas = window.document.createElement('canvas')
    canvas.width = origCtx.canvas.width
    canvas.height = origCtx.canvas.height
    const newCtx = canvas.getContext('2d', { willReadFrequently })
    if (copyOverImage) newCtx.drawImage(origCtx.canvas, 0, 0)
    return newCtx
}

/**
 * Converts a 'rgb[a](100, 242, 133)' or hex '#FF29E2' string to an rgb color object
 * 
 * @param colorStr RGB or hex color string
 * @returns Color object with r, g, b, a properties, alpha is 0-1
 */
export function parseColor(colorStr: string): Color {
    if (colorStr.startsWith('rgb')) {
        // Parse RGB string
        const colorPart = colorStr.split("(")[1].split(")")[0]
        const rgbParts = colorPart.split(',')
        const [r, g, b] = rgbParts.slice(0, 3).map(c => parseInt(c))
        const a = rgbParts.length === 4 ? parseFloat(rgbParts[3]) : 1
        return {r, g, b, a}
    } else if (colorStr.startsWith('#')) {
        // Parse HEX string
        const r = parseInt(colorStr.slice(1, 3), 16);
        const g = parseInt(colorStr.slice(3, 5), 16);
        const b = parseInt(colorStr.slice(5, 7), 16);
        const a = colorStr.length === 9 ? parseInt(colorStr.slice(7, 9), 16) : 256 // #RRGGBBAA
        return {r, g, b, a: a / 256}
    }
}