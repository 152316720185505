import { getData } from "./common/fetchUtils"
import { calcHeatmapCanvas, heatmapFilter } from "./heatmap/heatmapFilter"
import { OSDOverlayPlugin } from "./common/OSDOverlayPlugin"
import { Color, ColorOptions, ContinueFunction, HeatmapData, Label, LookupTable, MaskData, PolygonsData } from "./types"
import { DEFAULT_COLOR_MASK, DEFAULT_COLOR_POLYGONS, parseColor, log } from "./common/utils"
import { polygonFilter } from "./polygon/polygonFilter"
import { maskFilter } from "./mask/maskFilter"
import { circleFilter } from "./circles/circleFilter"

export class Anno2Manager {
    osdOverlayPlugin: OSDOverlayPlugin // Set on init
    overlays: Map<string, Anno2Overlay> = new Map()// uuid -> overlay

    constructor (viewer: OpenSeadragon.Viewer) {
        this.osdOverlayPlugin = new OSDOverlayPlugin(viewer)
    }

    /**
     * Add an Anno2 Annotation to the current OpenSeaDragon viewer using it's UUID.
     * 
     * @param uuid Universally Unique IDentifier of the annotation to load
     * @param colorStr String of "rgb[a]()" or "#FFEFEF" that the annotation should use as color
     * @param lineThickness Fixed size of the annotation lines in pixels of the highest resolution level
     */
    async addAnno2Overlay(uuid: string, colorStr?: string, lineThickness?: number) {
        // Check if this UUID is already added
        if (this.overlays.has(uuid)) {
            if (!this.overlays.get(uuid).isVisible) this.overlays.get(uuid).show()
            return;
        }
        // immediately set a dummy overlay while the real one is loading.
        this.overlays.set(uuid, new Anno2Overlay(uuid, this.osdOverlayPlugin, 'points', {}))
        this.osdOverlayPlugin.updateOverlay(() => {}, uuid)

        // load it and add it the the overlays
        log('Downloading and setting new overlay', this.osdOverlayPlugin, uuid, colorStr, lineThickness)

        const passedColor = colorStr != null ? parseColor(colorStr) : null
        const overlay = await this.#fetchAndSetOverlay(uuid, passedColor, lineThickness) 
        // After fetching the metadata, store it in the overlays
        this.overlays.set(uuid, overlay)
    }

    /**
     * Adds a anno2 overlay (also called filter) to the OpenSeaDragon viewer.
     * Also fetches the neccesary data from the server
     */
    async #fetchAndSetOverlay(uuid: string, color?: Color, fixedLineWidth?: number) {
        const dataPromises = await getData(uuid)
        const type = dataPromises.type
        
        // Only thing present now is metadata
        const overlay = new Anno2Overlay(uuid, this.osdOverlayPlugin, type, dataPromises.numItems, dataPromises.metadata)
        if (fixedLineWidth) overlay.colorOptions.fixedLineWidth = fixedLineWidth

        // Set the labels
        overlay.labels = dataPromises.labels
        
        const defaultColor = type === 'points' ? DEFAULT_COLOR_MASK : DEFAULT_COLOR_POLYGONS
        // Overwrite color with parameter color if set
        overlay.setColor(defaultColor)
        // Check if an alternative color was passed or the user added a color to the metadata object
        if (color) overlay.setColor(color)
        if (dataPromises.metadata.color) overlay.setColor(dataPromises.metadata.color)

        // Wait for the lookup tables to finish downloading, when done, add them to the available data
        dataPromises.lookupTableProms.forEach(lookupTableProm => {
            lookupTableProm.then((finishedLookupTable) => {
                overlay.availableData.lookupTables.push(finishedLookupTable)
                // And update the filter
                overlay.update()
            })
        })

        // Wait for the itemdata to finish
        dataPromises.itemDataProm.then((itemData) => {
            overlay.availableData.itemData = itemData
            overlay.update()
        })


        return overlay
    }
}

export class Anno2Overlay {
    uuid: string
    type: 'points' | 'polygons' | 'heatmap' | 'circles'
    osdOverlayPlugin: OSDOverlayPlugin // Set on init
    labels: Label[]
    metadata: any
    numItems: number | null

    availableData = {
        lookupTables: [] as LookupTable[],
        itemData: null as MaskData | PolygonsData | HeatmapData
    }

    colorOptions: ColorOptions = {
        fillColor: Object.assign({}, DEFAULT_COLOR_MASK),
        densityFill: 'fillColor',
        fixedLineWidth: null
    }

    constructor(
        uuid: string, osdOverlayPlugin: OSDOverlayPlugin, 
        type: 'points' | 'polygons' | 'heatmap' | 'circles', numItems: number | null, metadata: any
    ) {
        this.uuid = uuid
        this.type = type
        this.osdOverlayPlugin = osdOverlayPlugin
        this.metadata = metadata
        this.numItems = numItems
    }

    show() {
        if (this.isVisible) console.warn(`UUID: ${this.uuid} was already visible`)
        const enabler = this.osdOverlayPlugin.getOverlayEnabler(this.uuid)
        enabler()
    }
    
    /**
     * Hide the current overlay by disabling it in the overlayPlugin 
     */
    hide() {
        const disabler = this.osdOverlayPlugin.getOverlayDisabler(this.uuid)
        disabler()
    }

    /**
     * Sets the color, either use a string parse-able by parseColor() (rgba() or #FFEFEAF) or pass
     * a Color object.
     */
    setColor(newColor: string | Color) {
        if (newColor === 'Turbo') {
            this.colorOptions.densityFill = 'Turbo'
        } else {
            if (typeof newColor === 'string') newColor = parseColor(newColor)
            this.colorOptions.fillColor = newColor
            this.colorOptions.densityFill = 'fillColor'  
        }
        if (this.type === 'heatmap') this.update()
        this.osdOverlayPlugin.forceReload()
    }

    update() {
        const overlay = this
        const itemData = overlay.availableData.itemData
        if (overlay.type === 'heatmap') {
            // Calculate new canvas
            if (itemData && 'img' in itemData) {
                itemData.img.canvas = calcHeatmapCanvas(itemData.img.pngBytes, overlay.colorOptions)
                const heatmapFilterWithData = function(ctx: CanvasRenderingContext2D, next: ContinueFunction, tile: OpenSeadragon.Tile) {
                    return heatmapFilter(ctx, next, tile, itemData)
                }
                this.osdOverlayPlugin.updateOverlay(heatmapFilterWithData, this.uuid)
            }
        } else if (overlay.type === 'points'){
            const filterWithData = function(ctx: CanvasRenderingContext2D, next: ContinueFunction, tile: OpenSeadragon.Tile) {
                return maskFilter(ctx, next, tile, overlay.availableData.lookupTables, itemData as MaskData, overlay.colorOptions)
            }
            this.osdOverlayPlugin.updateOverlay(filterWithData, this.uuid)
        } else if (overlay.type === 'polygons') {
            const filterWithData = function(ctx: CanvasRenderingContext2D, next: ContinueFunction, tile: OpenSeadragon.Tile) {
                return polygonFilter(ctx, next, tile, overlay.availableData.lookupTables, itemData as PolygonsData, overlay.colorOptions, overlay.labels)
            }
            this.osdOverlayPlugin.updateOverlay(filterWithData, this.uuid)
        } else if (overlay.type === 'circles') {
            const filterWithData = function(ctx: CanvasRenderingContext2D, next: ContinueFunction, tile: OpenSeadragon.Tile) {
                return circleFilter(ctx, next, tile, overlay.availableData.lookupTables, itemData as MaskData, overlay.colorOptions, overlay.numItems)
            }
            this.osdOverlayPlugin.updateOverlay(filterWithData, this.uuid)
        } else throw new Error('Cannot update overlay because it has an unknown type!')
    }

    /**
     * Retrieves the current fillColor of this overlay as a Color object
     */
    get color() {
        // Return Color or rgba string?
        return this.colorOptions.fillColor
    }

    get isVisible() {
        const allUuids = Object.keys(this.osdOverlayPlugin.allOverlays)
        if (!allUuids.includes(this.uuid)) throw new Error('UUID not loaded into the osdOverlayPlugin!')
        const isDisabled = this.osdOverlayPlugin.disabledOverlays.includes(this.uuid)
        return !isDisabled
    }
}